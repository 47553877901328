var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isSlide)?_c('div',{staticClass:"position-absolute",on:{"click":_vm.toggleMenu}},[_vm._m(0)]):_vm._e(),_c('transition',{attrs:{"name":"slide-in"}},[(_vm.showMenu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleMenu),expression:"toggleMenu"}],staticClass:"responsive-bar-admin"},[_c('div',{staticClass:"responsive-bar-admin-custom",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoHome()}}},[_c('img',{attrs:{"src":require("@/assets/Logo2.png"),"width":"60px","height":"60px"}})]),_c('div',{staticClass:"responsive-bar-admin-menu my-3"},[_c('div',{staticClass:"menu-dropdown"},[_c('div',{staticClass:"main-dropdown d-flex justify-content-between",style:({
              'background-color': _vm.showDepreciationAction ? '#dfdfdf' : '#fff',
            }),on:{"click":function($event){_vm.showDepreciationAction = !_vm.showDepreciationAction}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("depreciation.acc_manage"))+" ")]),_c('img',{style:({
                transform: _vm.showDepreciationAction ? 'rotate(180deg)' : '',
              }),attrs:{"src":require("@/assets/arrow-dropdown.svg"),"alt":""}})]),_c('transition',{attrs:{"name":"down"}},[(_vm.showDepreciationAction)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'depreciation-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'depreciation-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.asset"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'deactivate-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'deactivate-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.do_deactivate"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'activate-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'activate-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.do_active"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'writeoff-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'writeoff-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.do_writeoff"))+" ")])]),(this.permissionStatus.permission.role.id_role === 2)?_c('div',{staticClass:"menu-dropdown-item position-relative",on:{"click":function($event){return _vm.$router.push({ name: 'confirm-action' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'confirm-action',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.confirm_action"))+" ")]),(_vm.haveDpNotification)?[_c('div',{staticClass:"pinging"}),_c('div',{staticClass:"ping"})]:_vm._e()],2):_vm._e()]):_vm._e()])],1),_c('div',{staticClass:"menu-dropdown"},[_c('div',{staticClass:"main-dropdown d-flex justify-content-between",style:({
              'background-color': _vm.showMonthlyDepreciation
                ? '#dfdfdf'
                : '#fff',
            }),on:{"click":function($event){_vm.showMonthlyDepreciation = !_vm.showMonthlyDepreciation}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("depreciation.monthly_depreciation"))+" ")]),_c('img',{style:({
                transform: _vm.showMonthlyDepreciation ? 'rotate(180deg)' : '',
              }),attrs:{"src":require("@/assets/arrow-dropdown.svg"),"alt":""}})]),_c('transition',{attrs:{"name":"down"}},[(_vm.showMonthlyDepreciation)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'depreciation-report' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'depreciation-report',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.depreciation_report"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'jv-document' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'jv-document',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.jv_document"))+" ")])])]):_vm._e()])],1),_c('div',{staticClass:"menu-dropdown"},[_c('div',{staticClass:"main-dropdown d-flex justify-content-between",style:({
              'background-color': _vm.showAccReport ? '#dfdfdf' : '#fff',
            }),on:{"click":function($event){_vm.showAccReport = !_vm.showAccReport}}},[_c('p',[_vm._v(_vm._s(_vm.$t("depreciation.acc_report")))]),_c('img',{style:({
                transform: _vm.showAccReport ? 'rotate(180deg)' : '',
              }),attrs:{"src":require("@/assets/arrow-dropdown.svg"),"alt":""}})]),_c('transition',{attrs:{"name":"down"}},[(_vm.showAccReport)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'paused-assets' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'paused-assets',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.pause_asset"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'using-expired-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'using-expired-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.asset_expired_use"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'sold-asset' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'sold-asset',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.sold_asset"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'accounting-note' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'accounting-note',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.financial_note"))+" ")])])]):_vm._e()])],1),_c('div',{staticClass:"menu-dropdown"},[_c('div',{staticClass:"main-dropdown d-flex justify-content-between",style:({
              'background-color': _vm.showOther ? '#dfdfdf' : '#fff',
            }),on:{"click":function($event){_vm.showOther = !_vm.showOther}}},[_c('p',[_vm._v(_vm._s(_vm.$t("depreciation.other")))]),_c('img',{style:({
                transform: _vm.showOther ? 'rotate(180deg)' : '',
              }),attrs:{"src":require("@/assets/arrow-dropdown.svg"),"alt":""}})]),_c('transition',{attrs:{"name":"down"}},[(_vm.showOther)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'construction-asset' })}}},[_c('p',{class:{
                    'menu-selected':
                      _vm.$route.name === 'construction-asset' ||
                      _vm.$route.name === 'construction-history',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.building_asset"))+" ")])]),_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'land-list' })}}},[_c('p',{class:{
                    'menu-selected':
                      _vm.$route.name === 'land-list' ||
                      _vm.$route.name === 'land-edit-log' ||
                      _vm.$route.name === 'land-log-detail',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.land"))+" ")])])]):_vm._e()])],1),_c('div',{staticClass:"menu-dropdown"},[_c('div',{staticClass:"main-dropdown d-flex justify-content-between",style:({
              'background-color': _vm.showSetting ? '#dfdfdf' : '#fff',
            }),on:{"click":function($event){_vm.showSetting = !_vm.showSetting}}},[_c('p',[_vm._v(_vm._s(_vm.$t("depreciation.setting")))]),_c('img',{style:({
                transform: _vm.showSetting ? 'rotate(180deg)' : '',
              }),attrs:{"src":require("@/assets/arrow-dropdown.svg"),"alt":""}})]),_c('transition',{attrs:{"name":"down"}},[(_vm.showSetting)?_c('div',{staticClass:"dropdown-list"},[_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'manage-financial-note' })}}},[_c('p',{class:{
                    'menu-selected':
                      _vm.$route.name === 'manage-financial-note' ||
                      _vm.$route.name === 'manage-sub-financial-note',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.acc_note_categoty"))+" ")])]),(this.permissionStatus.permission.role.id_role === 2)?_c('div',{staticClass:"menu-dropdown-item",on:{"click":function($event){return _vm.$router.push({ name: 'account-period' })}}},[_c('p',{class:{
                    'menu-selected': _vm.$route.name === 'account-period',
                  }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.account_period"))+" ")])]):_vm._e()]):_vm._e()])],1),_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.$router.push({ name: 'depreciation-status-history' })}}},[_c('p',{class:{
              'menu-selected': _vm.$route.name === 'depreciation-status-history',
            }},[_vm._v(" "+_vm._s(_vm.$t("depreciation.depreciation_history"))+" ")])])])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"burger-button"},[_c('span',{staticClass:"burger-bars line-style",staticStyle:{"top":"0%"}}),_c('span',{staticClass:"burger-bars line-style",staticStyle:{"top":"40%"}}),_c('span',{staticClass:"burger-bars line-style",staticStyle:{"top":"80%"}})])
}]

export { render, staticRenderFns }