<template>
  <span>
    <a v-if="permissionStatus.company.custom_id === 1">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadStandard"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }} (VTN)
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadStandard"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }} (VTN)
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 2">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadJagota"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }} (JGT)
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadJagota"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }} (JGT)
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 3">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadBualuang"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }} (BL)
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadBualuang"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }} (BL)
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 4">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadNinja"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadNinja"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 5">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadLearn"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadLearn"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 6">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadStandard"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadStandard"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 7">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadSingha"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadSingha"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 8">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadHoshi"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadHoshi"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
    <a v-if="permissionStatus.company.custom_id === 9">
      <b-button
        v-if="$route.name == 'Home'"
        class="button"
        @click="downloadSumitomo"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down />{{ $t("btn.dl_temp") }}
        </div></b-button
      >
      <button
        v-if="$route.name == 'docRegisAsset'"
        style="width: fit-content; height: 47px"
        class="download-template"
        @click="downloadSumitomo"
      >
        <div
          style="display: flex; align-items: center; justify-content: space-evenly"
        >
          <b-icon-file-earmark-arrow-down
            font-scale="1.5"
            style="margin-right: 3px"
          />{{ $t("btn.dl_temp") }}
        </div>
      </button>
    </a>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),
  },
  methods: {
    downloadStandard() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5.xlsx?" +
        time.valueOf();
    },
    downloadJagota() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Jagota.xlsx?" +
        time.valueOf();
    },
    downloadBualuang() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Bualuang.xlsx?" +
        time.valueOf();
    },
    downloadNinja() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Ninjavan.xlsx?" +
        time.valueOf();
    },
    downloadLearn() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Learn.xlsx?" +
        time.valueOf();
    },
    downloadSingha() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Singha.xlsx?" +
        time.valueOf();
    },
    downloadHoshi() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Hoshi.xlsx?" +
        time.valueOf();
    },
    downloadSumitomo() {
      let time = new Date();
      window.location.href =
        "https://api.verscan.com/download/VERSCAN_Template_V_1_5_Sumitomo.xlsx?" +
        time.valueOf();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.button {
  align-items: center;
  padding: 0;
  font-size: 12px;
  width: 180px;
  height: 45px;
}
.download-template {
  padding: 0 10px 0 10px;
  color: white !important;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box !important;
  border: solid 2px;
  border-radius: 11px;
  font-size: 14px;
}
</style>
