// ?? local
import store from "../store/index";
export const stage = () => {
  const url = window.location.hostname;
  switch (url) {
    case "app.verscan.com":
      return "prod";
    case "pre-prod-web.verscan.com":
      return "pre-prod";
    case "pre-prod-app.verscan.com":
      return "uat";
    case "staging-app.verscan.com":
      return "uat";
    case "dev-app.verscan.com":
      return "dev";
    case "postgres-staging-app.web.app":
    case "postgres-staging-app.firebaseapp.com":
      return "postgresuat";
    case "postgres-dev-app.web.app":
    case "postgres-dev-app.firebaseapp.com":
      return "postgresdev";
    case "localhost":
      return "uat";
    default:
      return null;
  }
};
export const baseUrl = (customName) => {
  const backend_url = process.env.VUE_APP_BACKEND_URL
  if (backend_url) {
    return backend_url
  }
  const custom = customName || store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/pre-prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api.verscan.com/dev/";
      case "local":
        return "https://api.verscan.com/dev/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://prod-api.verscan.com/";
    case "pre-prod":
      return "https://pre-prod-api.verscan.com/";
    case "uat":
      return "https://api.verscan.com/uat/";
    case "dev":
      return "https://api.verscan.com/dev/";
    case "postgresuat":
      return "https://api.verscan.com/uat2/";
    case "postgresdev":
      return "https://api.verscan.com/dev2/";
    case "local":
      return "https://api.verscan.com/dev/";
    default:
      return "http://localhost:3000/";
  }
};
export const borrowUrl = () => {
  switch (stage()) {
    case "prod":
      return "https://prod-api-borrow.verscan.com/v1.0/";
    case "pre-prod":
      return "https://api.verscan.com/borrow-pre-prod/v1.0/";
    case "uat":
      return "https://api.verscan.com/borrow-uat/v1.0/";
    case "postgresuat":
      return "https://api.verscan.com/borrow-postgres-uat/v1.0/";
    case "dev":
    case "postgresdev":
      return ""; //FIXME - change to postgres DB url
    case "local":
      return "https://api.verscan.com/borrow-dev/v1.0/";
    default:
      return "http://localhost:3000/";
  }
};

export const exportUrl = () => {
  const backend_url = process.env.VUE_APP_BACKEND_URL
  if (backend_url) {
    return backend_url
  }
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/pre-prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api2.verscan.com/dev/";
      case "local":
        return "https://api2.verscan.com/dev/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://prod-api.verscan.com/";
    case "pre-prod":
      return "https://pre-prod-api.verscan.com/";
    case "uat":
      return "https://api.verscan.com/uat/";
    case "dev":
      return "https://api.verscan.com/dev/";
    case "postgresuat":
      return "https://api.verscan.com/uat2/";
    case "postgresdev":
      return "https://api.verscan.com/dev2/";
    case "local":
      return "https://api.verscan.com/dev/";
    default:
      return "http://localhost:3000/";
  }
};

export const vpsUrl = () => {
  const backend_url = process.env.VUE_APP_BACKEND_URL
  if (backend_url) {
    return backend_url
  }
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    switch (stage()) {
      case "prod":
        return "https://api-op.verscan.com/jagota/prod/";
      case "pre-prod":
        return "https://api-op.verscan.com/jagota/pre-prod/";
      case "uat":
        return "https://api-op.verscan.com/jagota/uat/";
      case "dev":
        return "https://api2.verscan.com/dev/";
      case "local":
        return "https://api2.verscan.com/dev/";
      default:
        return "http://localhost:3000/";
    }
  }
  switch (stage()) {
    case "prod":
      return "https://api2.verscan.com/prod/";
    case "pre-prod":
      return "https://api2.verscan.com/prod/";
    case "uat":
      return "https://api2.verscan.com/uat/";
    case "dev":
      return "https://api2.verscan.com/dev/";
    case "local":
      return "https://api2.verscan.com/dev/";
    default:
      return "http://localhost:3000/";
  }
};

export const uploadUrl = () => {
  const custom = store.getters.getCustomName;
  if (custom === "jagota") {
    return "https://jagota-file-dot-modified-alloy-333307.as.r.appspot.com/api/upload/image";
  }
  switch (stage()) {
    case "prod":
      //FIXME - change this url to verscan domain
      return "https://verscan-register-dot-modified-alloy-333307.as.r.appspot.com/upload/image";
    case "pre-prod":
      return "https://api.verscan.com/file/api/file/create/";
    case "uat":
      return "https://api.verscan.com/file/api/file/create/";
    case "dev":
      return "https://api.verscan.com/file/api/file/create/";
    case "local":
      return "https://api.verscan.com/file/api/file/create/";
    default:
      return "https://api.verscan.com/file/api/file/create/";
  }
};
export default baseUrl;
