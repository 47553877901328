<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <!-- <Background></Background> -->
    <div class="scan-qr">
      <div class="">
        <h1 class="header-text">
          <div class="topic">{{ $t("scan.scanqr") }}</div>
        </h1>
      </div>
      <div class="pt-4">
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="!isNativeApp"
        >
          <!-- <img class="banner" src="../assets/camera.svg" alt="" /> -->

          <!-- <p class="detail">
            โปรดเคลื่อนย้ายกล้องของคุณ <br />ให้ตรงกับ QR Code
          </p> -->
          <!-- <div id="div1" /> -->
          <div class="camera-frame camera-frame-corner">
            <qrcode-stream
              @decode="onDecode"
              @init="onInit"
              v-if="!waitConfirm"
            >
              <!-- <img src="../assets/camera_inner.svg" class="camera_inner"> -->
            </qrcode-stream>
          </div>
          <div class="v-else">{{ error }}</div>
        </div>

        <!-- not found asset,  register asset -->

        <!-- found asset -->
        <!-- อยากเปลี่ยนทั้ง div ให้กดได้ -->
        <div id="toReplace">
          <div :is="currentComponent"></div>
        </div>

        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import { QrcodeStream } from "vue-qrcode-reader";
import { stage } from "../util/backend";
import base62 from "base62/lib/ascii";
import CustomQrName from "../util/CustomQrName.json";
// ? ใช้ lib จาก ->  https://github.com/gruhn/vue-qrcode-reader

const webStage = stage();
const isProductionStage = webStage === "pre-prod" || webStage === "prod";

export default {
  data() {
    return {
      isLoading: false,
      error: "",
      page: "",
      waitConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
    }),
  },
  components: { Footer, QrcodeStream, Loading },
  mounted() {
    this.page = this.$route.params.page;
    this.$store.commit("getDetailAssetsMutations", "");
  },
  methods: {
    // TODO - remove if new onDecode Function works
    // async onDecode(decodedString) {
    //   const id_company = await this.$store.dispatch("getCompanyIdCookie");

    //   const checkPattern = /(^https?:\/\/verscan.com\/check.*)/g;
    //   const checkPatternType2 = /(^vers.ly.*)/g;
    //   if (checkPattern.test(decodedString)) {
    //     // const param = decodedString.match(/([^/]+)$/);
    //     // const qrCode = param[0];
    //     const param = decodedString.replace("https://verscan.com/check/", "");
    //     const qrCode = encodeURIComponent(param);
    //     // const checkQRPattern = /\d{3}-\d{4}-\d{4}/g;
    //     // const id_company = await this.$store.dispatch("getCompanyIdCookie");
    //     if (this.page === "registerNow") {
    //       this.$router.push(`/check/${qrCode}/${this.page}`);
    //       return;
    //     }
    //     if (this.page === "changeQR") {
    //       this.$router.replace({
    //         path: `/check/${qrCode}/changeQR`,
    //         query: { qr: this.$route.query.qr, id: this.$route.query.id },
    //       });
    //       return;
    //     }
    //     if (!this.page) {
    //       this.$router.push(`/check/${qrCode}`);
    //       return;
    //     }
    //   } else if (checkPatternType2.test(decodedString)) {
    //     const param = decodedString.match(/([^/]+)$/);
    //     const baseNumber = param[0];
    //     const decodedNumber = base62.decode(baseNumber);
    //     let result = "";
    //     if ((id_company === 52 || id_company === 212) && isProductionStage) {
    //       const paddedNumber = String(decodedNumber).padStart(7, "0");
    //       result = `VTN${paddedNumber}`;
    //     } else {
    //       const paddedNumber = String(decodedNumber).padStart(11, "0");
    //       result = String(paddedNumber)
    //         .splice(3, 0, "-")
    //         .splice(8, 0, "-");
    //     }
    //     const qrCode = result;
    //     if (this.page === "registerNow") {
    //       this.$router.push(`/check/${qrCode}/${this.page}`);
    //       return;
    //     }
    //     if (this.page === "changeQR") {
    //       this.$router.push({
    //         path: `/check/${qrCode}/changeQR`,
    //         query: { qr: this.$route.query.qr, id: this.$route.query.id },
    //       });
    //       return;
    //     }
    //     if (!this.page) {
    //       this.$router.push(`/check/${qrCode}`);
    //       return;
    //     }
    //   } else if (
    //     (id_company === 257 || id_company === 258) &&
    //     decodedString &&
    //     isProductionStage
    //   ) {
    //     // --------- for 2 companies of bualuang -----------
    //     const qrCode = encodeURIComponent(decodedString);
    //     // alert(qrCode);
    //     if (this.page === "registerNow") {
    //       this.$router.push(`/check/${qrCode}/${this.page}`);
    //       return;
    //     }
    //     if (this.page === "changeQR") {
    //       this.$router.push({
    //         path: `/check/${qrCode}/changeQR`,
    //         query: { qr: this.$route.query.qr, id: this.$route.query.id },
    //       });
    //       return;
    //     }
    //     if (!this.page) {
    //       this.$router.push(`/check/${qrCode}`);
    //       return;
    //     }
    //   } else {
    //     Swal.fire({
    //       title: this.$t("dialog.wrong_qr"),
    //       text: this.$t("dialog.wrong_qr_dt"),
    //       timer: 3000,
    //       imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
    //       confirmButtonText: this.$t("btn.close"),
    //       imageHeight: 48,
    //       imageWidth: 48,
    //     });
    //   }
    // },

    async onDecode(decodedString) {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      const checkPattern = /(^https?:\/\/verscan.com\/check.*)/g;
      const checkPatternType2 = /(^vers.ly.*)/g;

      let parsedDecodedString = null;

      if (checkPattern.test(decodedString)) {
        const param = decodedString.replace("https://verscan.com/check/", "");
        const qrCode = encodeURIComponent(param);
        parsedDecodedString = qrCode;
      } else if (checkPatternType2.test(decodedString)) {
        const param = decodedString.match(/([^/]+)$/);

        const baseNumber = param[0];
        let result = "";
        if (baseNumber.slice(0, 1) == 0) {
          if (id_company === 323 && isProductionStage) {
            // Bangkok Mental Health Hospital only decoded
            const firstTwoChars = baseNumber.slice(0, 2);
            const removeFirst2 = baseNumber.substring(2);
            const decodedNumber = base62.decode(removeFirst2);
            const paddedNumber = String(decodedNumber).padStart(6, "0");
            const customName = CustomQrName[firstTwoChars];
            if (customName) {
              result = `${CustomQrName[firstTwoChars]}${paddedNumber}`;
            } else {
              return this.showInvalidQrCodeAlert();
            }
          }
        } else {
          if ((id_company === 52 || id_company === 212) && isProductionStage) {
            // VEJTAHNI only decoded
            const decodedNumber = base62.decode(baseNumber);
            const paddedNumber = String(decodedNumber).padStart(7, "0");
            result = `VTN${paddedNumber}`;
          } else {
            const decodedNumber = base62.decode(baseNumber);
            const paddedNumber = String(decodedNumber).padStart(11, "0");
            result = String(paddedNumber)
              .splice(3, 0, "-")
              .splice(8, 0, "-");
          }
        }
        parsedDecodedString = result;
      } else if (
        (id_company === 257 || id_company === 258) &&
        decodedString &&
        isProductionStage
      ) {
        // --------- for 2 companies of bualuang -----------
        const qrCode = encodeURIComponent(decodedString);
        parsedDecodedString = qrCode;
      } else {
        return this.showInvalidQrCodeAlert();
      }

      if (!parsedDecodedString) return this.showInvalidQrCodeAlert();

      if (!this.page) {
        this.$router.push(`/check/${parsedDecodedString}`);
        return;
      }

      if (this.page === "changeQR") {
        this.$router.replace({
          path: `/check/${parsedDecodedString}/changeQR`,
          query: { qr: this.$route.query.qr, id: this.$route.query.id },
        });
        return;
      }

      if (this.page === "registerNow") {
        this.$router.push(`/check/${parsedDecodedString}/${this.page}`);
        return;
      }
    },

    showInvalidQrCodeAlert() {
      Swal.fire({
        title: this.$t("dialog.wrong_qr"),
        text: this.$t("dialog.wrong_qr_dt"),
        timer: 3000,
        imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
        confirmButtonText: this.$t("btn.close"),
        imageHeight: 48,
        imageWidth: 48,
      });
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scan-qr {
  z-index: 2;
}
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.card-form {
  padding-top: 2rem;
  width: 80%;
  height: auto;
  padding: 1.5rem;
  padding-bottom: 1rem !important;
}

.banner {
  width: 40px;
  z-index: 1;
}

.detail {
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-size: 11px;
  color: #7c7c7c;
}
.camera-frame {
  height: 300px;
  width: 300px;
  border: 1px solid black;
  // margin: auto;
}
.swal2-icon.swal2-warning {
  border-color: red !important;
  color: red !important;
}
.swal2-styled.swal2-confirm {
  font-size: 14px;
}
.swal2-title {
  font-size: 14px !important;
  color: #000 !important;
}
.swal2-html-container {
  font-size: 12px !important;
  color: #7c7c7c !important;
  margin-top: 5px;
}
.swal2-popup {
  border-radius: 10px !important;
  padding: 0.75rem;
}
.swal2-styled.swal2-confirm {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border-radius: 8px !important;
}

.camera-frame-corner {
  z-index: 3;
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

@media only screen and (max-width: 420px) {
  .card-form {
    width: 90%;
    height: auto;
    padding: 1rem !important;
    // padding-bottom: 1rem !important;
  }

  .camera-frame {
    height: 280px !important;
    width: 280px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}

@media only screen and (min-width: 320px) {
  .card-form {
    width: 90%;
    height: auto;
    // padding: 1rem;
    // padding-bottom: 1rem !important;
  }

  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}
@media only screen and (min-width: 375px) {
  .card-form {
    // width: 90%;
    // height: auto;
    padding: 1rem !important;
    // padding-bottom: 1rem !important;
  }
  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}
@media only screen and (min-width: 768px) {
  .card-form {
    width: 310px !important;
    padding: 1rem !important;
  }

  .camera-frame {
    width: 255px !important;
    height: 285px !important;
    border: 1px solid black;
    // margin: auto;
    // margin-top: 1rem;
  }
}

div#toReplace {
  margin-top: 20px;
}

// button.sty-button {
//   width: 120px;
//   height: 40px;
//   margin-top: 5px;
//   border-radius: 15px;
//   border-color: transparent;
//   background: transparent linear-gradient(90deg, #0069da 0%, #004ea1 100%)
//     0% 0% no-repeat padding-box;
//   color: #fff;
//   font-size: 20px;
// }

// img.asset-image {
//   width: 80px;
//   height: auto;
// }

// button.see-more {
//   width: 36px;
//   height: auto;
//   padding-left: 20px;
//   border-color: transparent;
//   background: #fff;
//   img {
//     width: 12px;
//     height: auto;
//     opacity: 0.7;
//   }
// }

// .font-14 {
//   font-size: 14px;
// }

// .font-18 {
//   font-size: 18px;
// }
</style>
