<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="1"
      loader="dots"
    ></Loading>
    <div class="w-100" style="overflow: auto">
      <div class="back-icon">
        <img src="../assets/previous.svg" width="30" @click="gotoAllasset()" />
      </div>
      <h1 class="header-text">
        <div class="topic" style="padding-top: 0px !important">
          {{ $t("imgoneasset.as_img") }} <br />
          {{ $t("imgoneasset.as_num") }}:
          {{ getDetailAsset.asset.id_fixed_asset }}
        </div>
      </h1>

      <div class="card-form-login">
        <div class="asset-box">
          <!-- Regis img -->
          <div
            class="item-asset"
            v-if="this.first_img.url"
            data-toggle="modal"
            data-target="#editImageModal"
            @click="imagePopup(first_img.url, 'first_img')"
          >
            <Lazyloadimage class="banner" :image="this.first_img.url" alt="" />

            <div class="detail-asset">
              <span
                >{{ $t("imgoneasset.img_reg") }}<br />{{
                  $t("imgoneasset.date")
                }}
                {{ changeDateToLocaleDate(this.first_img.date) }}
                <div v-if="this.first_img.update">
                  ({{ $t("imgoneasset.recent_edit") }}
                  {{ changeDateToLocaleDate(this.first_img.update) }})
                </div>
              </span>
            </div>
          </div>
          <!-- Each img -->
          <div
            v-for="(asset, index) in gallery"
            :key="index"
            class="item-asset"
            data-toggle="modal"
            data-target="#editImageModal"
            @click="imagePopup(asset.image_url, asset.id_gallery)"
          >
            <!-- @click="goToDetail(asset.id_asset)" -->
            <Lazyloadimage class="banner" :image="asset.image_url" alt="" />
            <!-- src-placeholder="https://cdn-images-1.medium.com/max/80/1*xjGrvQSXvj72W4zD6IWzfg.jpeg" -->

            <div class="detail-asset">
              <span
                >{{ $t("imgoneasset.img_atdate") }}<br />{{
                  $t("imgoneasset.date")
                }}
                {{ changeDateToLocaleDate(asset.date) }}
                <div v-if="asset.update">
                  ({{ $t("imgoneasset.recent_edit") }}
                  {{ changeDateToLocaleDate(asset.update) }})
                </div>
              </span>
            </div>
          </div>
          <!-- Current img -->
          <div
            class="item-asset"
            v-if="this.current_img.url"
            data-toggle="modal"
            data-target="#editImageModal"
            @click="imagePopup(current_img.url, 'current_img')"
          >
            <Lazyloadimage
              class="banner"
              :image="this.current_img.url"
              alt=""
            />

            <div class="detail-asset">
              <span
                >{{ $t("imgoneasset.img_new") }}<br />{{
                  $t("imgoneasset.date")
                }}
                {{ changeDateToLocaleDate(this.current_img.date) }}</span
              >
            </div>
          </div>
          <!-- End Here -->
        </div>
      </div>

      <!-- Modal Popup -->
      <!-- <div
        class="modal fade"
        id="editImageModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header justify-content-end">
              <img
                src="@/assets/blue-x-circle.svg"
                data-dismiss="modal"
                style="cursor: pointer; float: right"
              />
            </div>
            <div class="modal-body">
              <img class="modal-image border-black" :src="this.modalImage" />
            </div>
            <div class="modal-footer justify-content-center">
              <label
                for="image"
                class="btn btn-edit btn-auto"
                v-if="uploadCheck == false"
              >
                {{ $t("imgoneasset.edit_img") }}
              </label>
              <input
                id="image"
                type="file"
                accept="image/png, image/jpeg"
                style="display: none; margin: 0px !important"
                @change="onFileChange($event)"
              />
              <button
                v-if="uploadCheck"
                type="button"
                class="btn"
                data-dismiss="modal"
                @click="saveChange()"
              >
                {{ $t("btn.save") }}
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Axios from "axios";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Lazyloadimage from "../components/Lazyloadimage.vue";
export default {
  data() {
    return {
      isLoading: true,
      modalImage: "",
      gallery: "",
      current_img: "",
      first_img: "",
      qrcode_id: "",
      url: "",
      uploadCheck: false,
      index: "",
      pictureFile: null,
      prevRoute: null,
    };
  },
  components: { Footer, Loading, Lazyloadimage },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      getDetailAsset: "getDetailAsset",
      getUserDetail: "getUserDetail",
    }),
  },
  async mounted() {
    this.qrcode_id = this.$route.params.id;
    await this.$store.dispatch("getDetailAssets", this.qrcode_id);
    this.isLoading = false;
    console.log("GET DETAIL ASSET", this.getDetailAsset);
  },
  watch: {
    getDetailAsset() {
      this.first_img =
        typeof this.getDetailAsset.asset.first_img_url === "string"
          ? JSON.parse(this.getDetailAsset.asset.first_img_url)
          : this.getDetailAsset.asset.first_img_url;
      if (this.getDetailAsset.asset.galleries) {
        this.gallery =
          typeof this.getDetailAsset.asset.galleries === "string"
            ? JSON.parse(this.getDetailAsset.asset.galleries)
            : this.getDetailAsset.asset.galleries;
      }
      this.current_img =
        typeof this.getDetailAsset.asset.image_url === "string"
          ? JSON.parse(this.getDetailAsset.asset.image_url)
          : this.getDetailAsset.asset.image_url;
    },
  },
  methods: {
    saveChange() {
      this.$store.dispatch("updateGallery", {
        index: this.index,
        url: this.modalImage,
        id_asset: this.qrcode_id,
      });
    },

    // start upload
    onFileChange(event) {
      console.log(event);
      const file = event.target.files[0];
      this.pictureFile = file;
      this.url = URL.createObjectURL(file);
      console.log("pic", file);
      this.upload();
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      console.log(`before compress`, this.pictureFile);
      console.log(`after compress`, fileforupload);

      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      console.log("fddd", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      this.modalImage = res.data.data.uploadFileAtLevel.url;
      this.uploadCheck = true;
      // Axios.post("https://api.verscan.com/file/api/file/create", formdata, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // }).then((res) => {
      //   this.modalImage = res.data.data.uploadFileAtLevel.url;
      //   this.uploadCheck = true;
      // });
    },
    // end upload
    imagePopup(link, index) {
      this.uploadCheck = false;
      this.index = index;
      this.modalImage = link;
    },
    changeDateToLocaleDate(date) {
      return moment(date).format("DD MMM YYYY HH:mm");
    },
    gotoAllasset() {
      // this.$router.push(`/detailOneAsset/${this.qrcode_id}`);
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.card-form-login {
  margin: 5%;
  margin-bottom: 120px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.asset-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  word-wrap: break-word;
}

.banner {
  width: 100%;
  height: 200px;
  z-index: 10;
  object-fit: cover;
  border: black 1px solid;
  // border-radius: 15px;
}

@media only screen and (min-width: 1025px) {
  .asset-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  // .card-form {
  //   width: 700px !important;
  // }
  .banner {
    width: 100%;
    height: 200px;
    z-index: 10;
    object-fit: cover;
    // border-radius: 15px;
  }
}
.modal-image {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.item-asset {
  cursor: pointer;
}
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
