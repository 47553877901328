<template>
  <div>
    <div>
      <Loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        :opacity="0.5"
        loader="dots"
      ></Loading>
      <!-- Filter Modal -->
      <b-modal
        id="filter-modal"
        @cancel="handleclear"
        :cancel-title="$t('btn.clear')"
        hide-header-close
        hide-header
        :ok-title="$t('btn.conf')"
        @ok="handleFilter"
      >
        <b-row>
          <b-col class="text-end" cols="2"> </b-col>

          <b-col class="text-center" cols="8">
            <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
          </b-col>
          <b-col class="text-end" cols="2">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('filter-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <label class="mt-2">{{ $t("home.cat") }}</label>
        <v-select
          :options="optionCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.sub_cat") }}</label>
        <v-select
          :options="optionSubCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_sub_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.as_stat") }}</label>
        <select
          class="form-control form-border"
          v-model="currentFilterOptions.status"
        >
          <option
            v-for="(stat, index) in statusData"
            :key="index"
            :value="stat.value"
          >
            {{ stat.name }}
          </option>
        </select>

        <label class="mt-2">{{ $t("home.build") }}</label>
        <v-select
          :options="optionBuilding"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_building"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.fl") }}</label>

        <v-select
          :options="optionFloor"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_floor"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.department") }}</label>
        <v-select
          :options="optionDepartment"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_department"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.zone") }}</label>
        <v-select
          :options="optionZone"
          label="label"
          :reduce="(option) => option.value"
          v-model="currentFilterOptions.id_location_zone"
          class="dropdown-style"
          :clearable="false"
        />
      </b-modal>

      <!-- modal 1: Choose sign or upload -->
      <b-modal id="sign" centered hide-header hide-footer
        ><b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('sign')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <img class="banner" src="../assets/contract.png" alt="" />

          <p class="detail">
            {{ $t("count.sign_cf") }}<br />
            {{ $t("count.sign_cf2") }}
          </p>
        </b-row>
        <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
          <button
            class="btn btn-edit mb-1 mb-sm-0"
            @click="selectUploadHandler"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
          >
            {{ $t("btn.up_sigimg") }}
          </button>
          <button
            for="signSig"
            class="btn btn-edit"
            @click="
              () => {
                this.selectUpload = false;
                $bvModal.show('sign-modal');
                $bvModal.hide('sign');
              }
            "
            v-if="isSignConfirm"
          >
            {{ $t("btn.sign") }}
          </button>
        </b-row></b-modal
      >

      <!-- modal 2: Sign signature -->
      <b-modal
        hide-header-close
        hide-header
        hide-footer
        centered
        id="sign-modal"
        ><b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('sign-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <!--Department Select  -->
        <div class="mx-auto" style="width: 85%">
          <label>{{ $t("count.select_dep") }}</label>
          <v-select
            :options="optionDepartment.slice(1)"
            label="label"
            v-model="signDepartment"
            class="dropdown-style"
            :clearable="false"
          />
        </div>
        <!-- End Department Select -->

        <!-- IF SELECT SIGN -->
        <b-row class="justify-content-center mt-4">
          <label
            style="width: 85%"
            v-if="!checkDepartmentSign(signDepartment)"
            >{{ $t("count.as_sign") }}</label
          >
          <label style="width: 85%" v-else
            >{{ $t("filter.dep") }} {{ signDepartment.label }}
            {{ $t("count.signed") }}</label
          >
          <!-- IF SELECT SIGN -->
          <b-row v-if="!selectUpload" class="justify-content-center">
            <VueSignaturePad
              v-if="!checkDepartmentSign(signDepartment)"
              id="signature"
              width="85%"
              height="200px"
              ref="signaturePad"
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
            />
            <img
              class="show-signature"
              v-else
              :src="checkDepartmentSign(signDepartment).signature_url"
            />
          </b-row>
          <!-- IF SELECT UPLOAD IMAGE -->
          <b-row v-else class="justify-content-center">
            <input
              ref="uploadSignCheck"
              type="file"
              accept="image/png, image/jpeg"
              style="display: none; margin: 0px !important"
              @change="onFileChange($event)"
            />
            <img
              v-if="url && !checkDepartmentSign(signDepartment)"
              :src="url"
              class="show-signature"
            />
            <img
              v-else-if="checkDepartmentSign(signDepartment)"
              class="show-signature"
              :src="
                checkDepartmentSign(signDepartment)
                  ? checkDepartmentSign(signDepartment).signature_url
                  : null
              "
            />
            <div v-else class="show-signature" />
          </b-row>
        </b-row>
        <!-- User Detail -->
        <b-row class="justify-content-center mt-2">
          <label style="width: 85%"
            >{{ $t("home.name") }}:
            <strong>{{ getUserDetail.name }}</strong></label
          >
          <label style="width: 85%"
            >{{ $t("home.pos") }}:
            <strong>{{ getUserDetail.position_company || "-" }}</strong></label
          >
          <label style="width: 85%"
            >{{ $t("home.dep") }}:
            <strong>{{ getUserDetail.department }}</strong></label
          >
          <label style="width: 85%"
            >{{ $t("home.access") }}:
            <strong>{{ userCompanyDetail.permission.role.name }}</strong></label
          >
        </b-row>
        <!-- End User Detail -->

        <b-row
          class="d-flex mt-4 justify-content-evenly"
          v-if="!checkDepartmentSign(signDepartment)"
        >
          <button
            v-if="!selectUpload"
            class="btn mb-1 mb-sm-0"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
            @click="clear"
          >
            {{ $t("btn.del_all") }}
          </button>
          <button
            v-else
            class="btn mb-1 mb-sm-0"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
            @click="$refs.uploadSignCheck.click()"
          >
            {{ $t("count.upimg") }}
          </button>
          <button
            class="btn btn-outline-primary"
            :disabled="!url && selectUpload"
            @click="
              () => {
                save('sign');
              }
            "
          >
            {{ $t("btn.save_sig") }}
          </button>
        </b-row>
        <b-row v-else class="d-flex mt-4 justify-content-evenly">
          <button class="btn" @click="$bvModal.hide('sign-modal')">
            {{ $t("btn.close") }}
          </button>
        </b-row>
      </b-modal>

      <!-- modal 3: Confirm signature -->
      <b-modal
        id="confirm-signature"
        hide-header-close
        hide-header
        hide-footer
        centered
      >
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('confirm-signature')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <p class="detail" style="width: 85%">
            {{ $t("count.do_you") }} <b>{{ $t("btn.conf") }}</b>
            {{ $t("count.certify") }} {{ $t("home.dep") }}:
            <b>{{ signDepartment.label }}</b> {{ $t("count.or_not") }}
          </p>
        </b-row>
        <b-row class="justify-content-center">
          <img v-if="url" :src="url" height="100%" class="show-signature" />
          <p class="mt-2" style="width: 85%;">
            {{ $t("count.name") }}: <b>{{ getUserDetail.name }}</b
            ><br />
            {{ $t("home.pos") }}:
            <strong>{{ getUserDetail.position_company || "-" }}</strong>
            <br />
            {{ $t("home.dep") }}:<strong>{{ getUserDetail.department }}</strong>
            <br />
            {{ $t("home.access") }}:
            <strong>{{ userCompanyDetail.permission.role.name }}</strong>
            <br />
            {{ $t("count.date_time") }}: <b>{{ formatDate(new Date()) }} </b>
          </p>
        </b-row>
        <b-row class="justify-content-evenly mt-4">
          <button
            class="btn mb-1 mb-sm-0"
            style="color: white; background: #bcbaba"
            @click="$bvModal.hide('confirm-signature')"
          >
            {{ $t("btn.canc") }}
          </button>
          <button
            class="btn btn-outline-primary"
            @click="
              () => {
                sendSignature();
                $bvModal.hide('sign');
                $bvModal.hide('sign-modal');
                $bvModal.hide('confirm-signature');
              }
            "
          >
            {{ $t("btn.conf") }}
          </button>
        </b-row>
      </b-modal>

      <!-- modal 4: show signatures -->
      <b-modal id="show-signature" hide-footer hide-header>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('show-signature')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>

        <!-- New Feature -->
        <div v-if="checkNewDepartmentFeature">
          <label style="width: 85%">{{ $t("count.dt_dep") }}</label>
          <!-- <select
          <b-row class="justify-content-center">
            <label style="width: 85%">{{ $t("count.dt_dep") }}</label>
            <v-select
              :options="optionDepartment.slice(1)"
              v-model="signDepartment"
              class="dropdown-style"
              :clearable="false"
            />
            <select
              style="width: 80%"
              class="form-control"
              v-model="signDepartment"
            >
              <option
                v-for="(list, index) in optionDepartment"
                :key="index"
                :value="list"
              >
                {{ $t("home.dep") }} {{ list }}
              </option>
            </select> -->
          <b-row>
            <v-select
              :options="optionDepartment.slice(1)"
              label="label"
              v-model="signDepartment"
              class="dropdown-style"
              :clearable="false"
            />
          </b-row>
          <b-row class="justify-content-center mt-2">
            <div style="width: fit-content">
              <b>{{ $t("count.sign_conf") }}</b>
            </div>
          </b-row>
          <b-row
            class="pt-2"
            style="font-size: 14px"
            v-if="upperSignDetail.length > 0"
          >
            <b-col
              cols="6"
              class="d-flex align-items-center flex-column"
              v-for="(sign, index) in upperSignDetail"
              :key="index"
              ><img class="show-all-signature" :src="sign.signature_url" />
              <div class="pt-1" style="text-align: center">
                <p class="show-name">
                  {{ $t("count.name") }} {{ sign.user.name }}
                </p>
                <p class="show-name">{{ $t("home.pos") }} {{ sign.role }}</p>
                <p class="show-name">
                  {{ $t("count.date_time") }}
                  {{ formatDate(sign.signed_date) }}
                </p>
              </div></b-col
            >
          </b-row>
          <b-row
            v-else
            style="height: 150px"
            class="justify-content-center align-items-center"
          >
            {{ $t("count.no_sign") }}
          </b-row>
          <hr style="color: black" />
          <b-row class="pt-2" v-if="lowerSignDetail.length > 0">
            <b-col
              cols="6"
              class="d-flex align-items-center flex-column"
              v-for="(sign, index) in lowerSignDetail"
              :key="index"
              ><img class="show-all-signature" :src="sign.signature_url" />
              <div class="pt-1" style="text-align: center">
                <p class="show-name">
                  {{ $t("count.name") }} {{ sign.user.name }}
                </p>
                <p class="show-name">{{ $t("home.pos") }} {{ sign.role }}</p>
                <p class="show-name">
                  {{ $t("count.date_time") }} {{ formatDate(sign.signed_date) }}
                </p>
              </div></b-col
            >
          </b-row>
          <b-row
            v-else
            style="height: 150px"
            class="justify-content-center align-items-center"
          >
            {{ $t("count.no_sign") }}
          </b-row>
        </div>
        <!-- Old Feature -->
        <div v-else>
          <b-row class="justify-content-center mt-2">
            <div style="width: fit-content">
              <b>{{ $t("count.cert_sig") }}</b>
            </div>
            <b-row class="pt-2 overflow-auto" v-if="jsonApprove.length !== 0">
              <b-col
                cols="6"
                style="font-size: 14px"
                class="d-flex align-items-center flex-column "
                v-for="(sign, index) in jsonApprove"
                :key="index"
              >
                <div style="margin-bottom: 5rem;">
                  <img class="show-all-signature" :src="sign.signature_url" />
                  <p class="pt-1" stlye="font-size:14px; color:#007afe;">
                    {{ $t("count.name") }} {{ sign.user.name }} <br />
                    {{ $t("home.pos") }} {{ sign.role }} <br />
                    {{ $t("count.date_time") }}
                    {{ formatDate(sign.signed_date) }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <div
              v-else
              style="height: 150px"
              class="d-flex justify-content-center align-items-center"
            >
              {{ $t("count.no_sign") }}
            </div>
          </b-row>
        </div>
      </b-modal>

      <!-- modal 5: alert department -->
      <b-modal id="alert-department" hide-footer hide-header centered>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('alert-department')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-center">
          <p class="detail">
            {{ $t("count.dep_count") }}
            {{ checkDepartmentCountingEnd.length }}
            {{ $t("count.dep_count1") }}
          </p>
          <div
            v-for="(each, index) of checkDepartmentCountingEnd"
            :key="index"
            class="department-remain"
            style="background-color: lightgrey; width: 85%"
          >
            {{ index + 1 }}. {{ $t("home.dep") }}{{ each }}
          </div>
          <hr class="mt-1" />
          <label class="detail" style="text-align: center">{{
            $t("count.end_count")
          }}</label>
        </b-row>
        <b-row class="justify-content-evenly mt-2">
          <button
            class="btn mb-1 mb-sm-0"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
            @click="$bvModal.hide('alert-department')"
          >
            {{ $t("btn.canc") }}
          </button>
          <button
            class="btn"
            @click="
              () => {
                $bvModal.hide('alert-department');
                $bvModal.show('sign-end');
              }
            "
          >
            {{ $t("btn.conf") }}
          </button></b-row
        >
      </b-modal>
      <!-- modal 6: end counting -->
      <b-modal id="sign-end" centered hide-header hide-footer
        ><b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('sign-end')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <img class="banner" src="../assets/contract.png" alt="" />

          <p class="detail">{{ $t("count.sign_to_endcount") }}</p>
        </b-row>
        <b-row class="d-flex justify-content-evenly" style="font-size: 14px">
          <button
            class="btn btn-edit btn-auto mb-1 mb-sm-0"
            @click="
              () => {
                this.url = '';
                this.selectUpload = true;
                $bvModal.hide('sign-end');
                $bvModal.show('sign-end-modal');
              }
            "
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
          >
            {{ $t("btn.up_sigimg") }}
          </button>
          <button
            for="signSig"
            class="btn btn-edit btn-auto"
            @click="
              () => {
                this.selectUpload = false;
                $bvModal.hide('sign-end');
                $bvModal.show('sign-end-modal');
              }
            "
            v-if="isSignConfirm"
          >
            {{ $t("btn.sign") }}
          </button>
        </b-row></b-modal
      >

      <!-- modal 7: sign-end -->
      <b-modal id="sign-end-modal" centered hide-header hide-footer>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('sign-end-modal')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <label style="width: 85%; text-align: center">{{
            $t("count.sign_to_endcount")
          }}</label>
        </b-row>
        <b-row v-if="!selectUpload" class="justify-content-center">
          <VueSignaturePad
            id="signature"
            width="85%"
            height="200px"
            ref="signaturePad"
            :options="{
              onBegin: () => {
                $refs.signaturePad.resizeCanvas();
              },
            }"
          />
        </b-row>
        <!-- IF SELECT UPLOAD IMAGE -->
        <b-row v-else class="justify-content-center">
          <input
            ref="uploadSign"
            type="file"
            accept="image/png, image/jpeg"
            style="display: none; margin: 0px !important"
            @change="onFileChange($event)"
          />
          <img v-if="url" :src="url" class="show-signature" />
          <div v-else class="show-signature" />
        </b-row>
        <!-- User Detail -->
        <b-row class="justify-content-center mt-2">
          <label style="width: 85%"
            >{{ $t("count.name") }}:
            <strong>{{ getUserDetail.name }}</strong></label
          >
          <label style="width: 85%"
            >{{ $t("home.pos") }}:
            <strong>{{ userCompanyDetail.permission.role.name }}</strong></label
          >
        </b-row>
        <b-row class="justify-content-evenly mt-2">
          <button
            v-if="!selectUpload"
            class="btn mb-1 mb-sm-0"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
            @click="clear"
          >
            {{ $t("btn.del_all") }}
          </button>
          <button
            v-else
            class="btn mb-1 mb-sm-0"
            style="
              border: 2px solid #007afe;
              background: transparent !important;
              color: #007afe;
            "
            @click="$refs.uploadSign.click()"
          >
            {{ $t("btn.upimg") }}
          </button>
          <button
            class="btn btn-outline-primary"
            :disabled="!url && selectUpload"
            @click="
              () => {
                save('sign-end');
              }
            "
          >
            {{ $t("btn.save_sig") }}
          </button>
        </b-row>
      </b-modal>

      <!-- modal 8: sign-end-confirm -->
      <b-modal id="sign-end-confirm" centered hide-header hide-footer>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('sign-end-confirm')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <label style="width: 85%; text-align: center">{{
            $t("count.conf_end_count")
          }}</label>
        </b-row>
        <b-row class="justify-content-center">
          <img v-if="url" :src="url" height="100%" class="show-signature" />
          <p class="mt-2" style="width: 85%;">
            {{ $t("count.name") }}: <b>{{ getUserDetail.name }}</b
            ><br />
            {{ $t("home.pos") }}:
            <b>{{ userCompanyDetail.permission.role.name }}</b
            ><br />
            {{ $t("count.date_time") }}: <b>{{ formatDate(new Date()) }} </b>
          </p>
        </b-row>
        <b-row class="justify-content-evenly mt-4">
          <button
            class="btn mb-1 mb-sm-0"
            style="color: white; background: #bcbaba"
            @click="$bvModal.hide('sign-end-confirm')"
          >
            {{ $t("btn.canc") }}
          </button>
          <button
            class="btn btn-outline-primary"
            @click="
              () => {
                $bvModal.hide('sign-end-confirm');
                $bvModal.hide('sign-end-modal');
                $bvModal.hide('sign-end');
                $bvModal.hide('alert-department');
                $bvModal.show('send-mail');
              }
            "
          >
            {{ $t("btn.conf") }}
          </button>
        </b-row>
      </b-modal>

      <!-- modal 9: show sign end -->
      <b-modal id="show-sign-end" centered hide-header hide-footer>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('show-sign-end')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="justify-content-center mt-2">
          {{ $t("count.signature_end") }}
        </b-row>
        <b-row class="justify-content-center mt-2">
          <img
            class="show-signature"
            :src="endSignature ? endSignature.signature_url : null"
          />
          <p class="pt-1" style="text-align: center">
            {{ $t("count.name") }}
            {{ endSignature ? endSignature.user.name : null }} <br />
            {{ $t("home.pos") }} {{ endSignature ? endSignature.role : null }}
            <br />
            {{ $t("count.date_time") }}
            {{
              formatDate(endSignature ? endSignature.signed_date : new Date())
            }}
          </p>
        </b-row>
      </b-modal>

      <!-- modal 10: send email to technician -->
      <b-modal id="send-mail" centered hide-header hide-footer>
        <b-row>
          <b-col class="text-end">
            <button
              class="close_modal text-light border-none"
              @click="$bvModal.hide('send-mail')"
            >
              <b-icon-x scale="1.5" />
            </button>
          </b-col>
        </b-row>
        <b-row class="text-center mb-3">
          <h2>{{ $t("count.to_tech") }}</h2>
        </b-row>
        <b-row class="mx-2">
          <p>{{ $t("count.as_stat") }}</p>
          <b class="mx-1"> 1. {{ $t("count.wait_as") }}</b>
          <b class="mx-1"> 2. {{ $t("count.repar") }}</b>
          <b class="mx-1"> 3. {{ $t("count.repairing") }}</b>
          <p class="mt-2">{{ $t("count.will_send") }}</p>
        </b-row>
        <b-row class="justify-content-evenly mt-4">
          <button
            class="btn mb-1 mb-sm-0"
            style="color: white; background: #bcbaba"
            @click="$bvModal.hide('send-mail')"
          >
            {{ $t("btn.canc") }}
          </button>
          <button
            class="btn btn-outline-primary"
            @click="
              () => {
                $bvModal.hide('sign-end-confirm');
                $bvModal.hide('sign-end-modal');
                $bvModal.hide('sign-end');
                $bvModal.hide('alert-department');
                $bvModal.hide('send-mail');
                endCountingDate();
              }
            "
          >
            {{ $t("btn.conf") }}
          </button>
        </b-row>
      </b-modal>

      <!-- Export Modal -->
      <b-modal
        class="p-2"
        id="export-modal"
        hide-header-close
        hide-header
        hide-footer
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('export-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <p class="text-center pb-3">{{ $t("asset.choose_ex") }}</p>
        <b-row align-h="center">
          <!-- <button class="px-2 email-me" @click="onExport(true)">
            {{ $t("btn.send_mail") }}
          </button> -->

          <button class="px-2 download-file" @click="onExport(false)">
            {{ $t("btn.dl_file") }}
          </button>
        </b-row>
      </b-modal>
      <!-- HEADER -->

      <div class="back-icon">
        <img src="../assets/previous.svg" width="30" @click="gotoDashboard()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("dashboard.sum_re") }}</div>
      </h1>
      <!-- END HEADER -->
      <div class="card-div pt-4">
        <div class="pl-4">
          <div class="text-left pb-2 col text-xl">
            <div class="flex-header">
              <p>{{$t('asset.company_name')}} : {{ companyName }}</p>
            </div>
            <div class="d-flex justify-content-center mb-2 flex-column">
              <p class="sub-label head-date mx-0 col-12 text-center">
                {{ $t("count.as_counton")
                }}{{
                  startDate[0] +
                    $t("dashboard.at") +
                    " " +
                    startDate[2] +
                    " " +
                    startDate[1] +
                    " " +
                    startDate[3]
                }}
              </p>
              <p
                class="mx-0 pl-1 sub-label head-date col-12 text-center"
                v-if="endDate"
                style="padding-left: 1rem"
              >
                {{ $t("count.and") }} {{ $t("count.end") }}
                {{
                  endDate[0] +
                    " " +
                    endDate[2] +
                    " " +
                    endDate[1] +
                    " " +
                    endDate[3]
                }}
              </p>
            </div>
          </div>

          <div class="button-bar mb-2">
            <div class="button-bar-group" style="place-content: flex-start">
              <button
                v-if="!isNativeApp"
                class="button-load p-sm-1 px-1"
                @click="$bvModal.show('export-modal')"
              >
                <b-icon-upload
                  class="p-1"
                  font-scale="2"
                  style="color: #007afe"
                ></b-icon-upload
                >{{ $t("btn.ex_file") }}
              </button>

              <button
                class="button-load p-sm-1 px-1"
                @click="$bvModal.show('show-signature')"
              >
                <b-icon-eye-fill
                  font-scale="2"
                  class="p-1"
                  style="color: #007afe"
                ></b-icon-eye-fill>
                {{ $t("btn.view_cersig") }}
              </button>
              <button
                class="button-load p-sm-1 px-1 btn-auto"
                @click="$bvModal.show('sign')"
                v-if="isSignConfirm && checkEndCountingDate()"
              >
                <img class="p-1" src="../assets/signature.svg" />
                {{ $t("btn.sign_cerdoc") }}
              </button>
              <!-- checkEverSign(getUserDetail.id_user) && -->
              <!-- data-dismiss="modal"
                data-toggle="modal"
                data-target="#sign" -->

              <!-- For Department Sign Feature -->
              <button
                class="button-load p-sm-1 p-1 end-counting"
                v-if="
                  userCompanyDetail.permission.role.name ===
                    'Accounting Manager' &&
                    checkNewDepartmentFeature &&
                    new Date(summaryData.summary.counting_date).getTime() <=
                      Date.now()
                "
                @click="
                  () => {
                    if (
                      checkDepartmentCountingEnd.length != 0 &&
                      summaryData.summary.is_finish === false
                    ) {
                      $bvModal.show('alert-department');
                    } else if (summaryData.summary.is_finish) {
                      $bvModal.show('show-sign-end');
                    } else {
                      $bvModal.show('sign-end');
                    }
                  }
                "
              >
                <img class="p-1" src="../assets/end-counting-icon.svg" />

                <span v-if="!summaryData.summary.is_finish">{{
                  $t("count.count_end")
                }}</span>
                <span v-else>{{ $t("count.view_endsig") }}</span>
              </button>
            </div>
            <div style="display: flex; justify-content: space-between">
              <div class="input-group">
                <TmpFilter
                  :searchTerm="searchText"
                  @set-search-term="
                    (text) => {
                      searchText = text;
                      searchByText();
                    }
                  "
                />
                <span
                  class="input-group-text"
                  id="basic-addon1"
                  style="border-radius: 10px 0 0 10px"
                >
                  <!-- @click="resetSearch" -->
                  <img src="../assets/g-search.svg" width="20px" />
                </span>
                <input
                  type="text"
                  class="searching"
                  style="
                    width: 100%;
                    border: none !important;
                    padding: 1rem;
                    background-color: #e0e0e0 !important;
                    border-radius: 0 10px 10px 0;
                    height: 45px;
                    margin-right: 1rem;
                  "
                  :placeholder="$t('home.search')"
                  :aria-label="$t('home.search')"
                  v-model="searchText"
                  v-debounce:700ms="searchByText"
                />
              </div>
              <button
                v-if="!summaryData.summary.is_finish"
                class="filter"
                style="border: none; width: fit-content"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <img src="@/assets/filter-white.svg" />
              </button>
            </div>
          </div>
          <div class="overflow_table overflow-auto">
            <report-table
              :dataTable="datatable"
              :page="currentPage"
              ref="selectedAsset"
            />
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalpages"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { authHeader } from "../store/actions";
import Compressor from "compressorjs";
import { mapGetters } from "vuex";
import moment from "moment";
import Loading from "vue-loading-overlay";
import ReportTable from "@/components/Table/ReportTable.vue";
import { baseUrl } from "../util/backend.js";
import TmpFilter from "@/components/CustomComponent/customTmpFilter.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      signDepartment: {},
      company_id: "",
      selectUpload: false,
      isLoading: true,
      pictureFile: null,
      url: "",
      signShow: false,
      filter_year: [],
      searchText: "",
      checked: {
        id: [],
        name: [],
      },
      startDate: "",
      endDate: "",
      monthNames: [
        this.$t("adddate.jan"),
        this.$t("adddate.feb"),
        this.$t("adddate.mar"),
        this.$t("adddate.apr"),
        this.$t("adddate.may"),
        this.$t("adddate.june"),
        this.$t("adddate.july"),
        this.$t("adddate.aug"),
        this.$t("adddate.sep"),
        this.$t("adddate.oct"),
        this.$t("adddate.nov"),
        this.$t("adddate.dec"),
      ],
      dayNames: [
        this.$t("adddate.sun"),
        this.$t("adddate.mon"),
        this.$t("adddate.tue"),
        this.$t("adddate.wed"),
        this.$t("adddate.thu"),
        this.$t("adddate.fri"),
        this.$t("adddate.sat"),
      ],
      filterdata: [],
      sortOrder: "",
      searchList: "",
      selectColum: "",
      data: "",
      currentFilterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
      },
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
      },
      isSigned: true,
      isData: false,
      disabled_filterbtn: true,
      isFilter: true,
      listarr: [],
      isChecked: false,
      signSig: {
        url: "",
        id_user: "",
        date: "",
        position: "",
      },
      countingDate: {
        name_day: "",
        day: "",
        name_month: "",
        year: "",
      },
      arrCountingDate: "",
      statusData: [
        { name: this.$t("filter.all"), value: "ทั้งหมด" },
        { name: this.$t("count.usable"), value: "available" },
        { name: this.$t("count.not_inuse"), value: "unavailable" },
        { name: this.$t("count.broke"), value: "broke" },
        { name: this.$t("count.repairing"), value: "maintain" },
        { name: this.$t("count.irrepar"), value: "cannot_repair" },
        { name: this.$t("filter.lost"), value: "lost" },
        { name: this.$t("asset.not_count"), value: "uncounting" },
        { name: this.$t("asset.not_reg"), value: "unregister" },
      ],
      // statusData: [
      //   { name: this.$t("filter.all"), value: "ทั้งหมด" },
      //   { name: this.$t("count.usable"), value: "ใช้งานได้" },
      //   { name: this.$t("count.not_inuse"), value: "ไม่ได้ใช้งาน" },
      //   { name: this.$t("count.wait_as"), value: "รอการประเมิน" },
      //   { name: this.$t("count.repar"), value: "ซ่อมได้" },
      //   { name: this.$t("count.irrepar"), value: "ซ่อมไม่ได้" },
      //   { name: this.$t("filter.rep_lost"), value: "report" },
      //   { name: this.$t("filter.lost"), value: "lost" },
      //   { name: this.$t("asset.not_reg"), value: "ยังไม่ได้ลงทะเบียน" },
      //   { name: this.$t("asset.reg"), value: "ลงทะเบียนแล้ว" },
      //   { name: this.$t("asset.not_count"), value: "ยังไม่ได้ตรวจนับ" },
      // ],
    };
  },
  components: { Loading, ReportTable, TmpFilter },
  methods: {
    searchByText() {
      this.currentFilterOptions.text = this.searchText;
      this.filterOptions = { ...this.currentFilterOptions };
      this.getSummaryData();
    },
    fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
        ),
      }));
    },
    async selectUploadHandler() {
      this.url = "";
      this.selectUpload = true;
      this.$bvModal.show("sign-modal");
      this.$bvModal.hide("sign");
    },
    formatDate(date) {
      if (this.$i18n.locale === "th") {
        return moment(date).format("ll เวลา HH:mm");
      } else {
        return moment(date).format("DD MMM YYYY [at] HH:mm");
      }
    },
    checkDepartmentSign(department) {
      const checkDepartment = this.jsonApprove.find((each) => {
        return (
          this.getUserDetail.id_user === each.id_user &&
          department.label == each.department // FIXME change to department ID
        );
      });
      return checkDepartment;
    },
    handleFilter() {
      this.filterOptions = { ...this.currentFilterOptions };
      console.log("this.currentFilterOptions", this.currentFilterOptions);
      console.log("this.filterOptions", this.filterOptions);
      this.getSummaryData();
    },
    handleclear() {
      this.searchText = "";
      this.currentFilterOptions = {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        status: "ทั้งหมด",
      };
      this.filterOptions = {
        text: "",
        category: null,
        sub_category: null,
        status: "ทั้งหมด",
        building: null,
        floor: null,
        department: null,
        zone: null,
      };
      this.getSummaryData();
    },
    checkEndCountingDate() {
      let check = false;
      if (this.summaryData.summary.is_finish) {
        check = true;
      } else if (
        moment(this.summaryData.summary.counting_date).isAfter(
          new Date(),
          "day"
        )
      ) {
        check = true;
      } else {
        check = false;
      }
      return !check;
    },
    checkEverSign(idUser) {
      let check = true;
      if (this.summaryData.summary.signatures.length > 0) {
        check = this.jsonApprove.some((item) => item.id_user == idUser);
      } else {
        check = false;
      }
      console.log("check found id: ", check);
      return !check;
    },
    setDayMonthYear() {
      let arr = this.summaryData.summary.counting_date.split();
      return console.log("array of counting date: ", arr);
    },
    async sendSignature() {
      const id_summary = this.$route.params.id_summary;
      const data = {
        id_summary: id_summary,
        signature: this.signSig,
        isSignComplete: this.isSignComplete,
      };

      await this.$store.dispatch("setSignature", data);
      this.url = null;
      this.url_new = null;
      this.pictureFile = null;
      this.signSig.url = null;

      this.getSummaryData();
    },

    async endCountingDate() {
      this.isLoading = true;
      const id_summary = this.$route.params.id_summary;
      this.signSig.department = null;
      const data = {
        id_summary: id_summary,
        signature: this.signSig,
      };
      await this.$store.dispatch("endCountingDate", data);
      this.url = null;
      this.url_new = null;
      this.pictureFile = null;
      this.signSig.url = null;
      this.isLoading = false;
      this.$router.replace("/history");
    },
    async onExport(isMail) {
      this.isLoading = true;
      this.$store
        .dispatch("ExportAsset", {
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.currentFilterOptions,
          filename: `${this.$t("dashboard.sum_re")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          id_summary: this.$route.params.id_summary,
          type: "ReportCount",
        })
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    searchfunc() {
      this.data = this.searchCom;
    },
    gotoSign() {
      this.$router.push(`/sign`);
    },
    gotoDashboard() {
      this.$router.push(`/dashboard/${this.$route.params.id_summary}`);
    },
    filterbydata(value) {
      this.filterdata = value;
    },
    sortOrderby(value) {
      this.sortOrder = value;
    },
    selectColums(value) {
      console.log("val", value);
      this.selectColum = value;
    },
    submitdata(value) {
      this.filterbydata(value);
      this.filterData();
      this.sortData();
    },
    clearFilter() {
      this.data = this.summaryData.allCountingItem;
      this.isFilter = true;
      this.filterdata = [];
      this.checked = {
        id: [],
        name: [],
      };
    },
    clearSearch() {
      let data = this.summaryData.result.data.rows;
      let allArray = [];
      Object.keys(data).map(async (each) => {
        if (each !== "allAsset") {
          await allArray.push(...data[each]);
        }
      });
      this.data = allArray;
    },
    sortData() {
      if (this.selectColum === "รหัสสินทรัพย์ถาวร") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.id_fixed_asset.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      if (this.selectColum === "ชื่อสินทรัพย์") {
        if (this.sortOrder) {
          this.data = this.lodash.orderBy(
            this.data,
            [(a) => a.name.toLowerCase()],
            [this.sortOrder]
          );
        }
      }
      console.log("Report Orderby > ", this.data);
    },
    filterData() {
      if (this.selectColum === "รหัสสินทรัพย์ถาวร") {
        console.log("filterdata infunction", this.filterdata);
        this.data = this.data.filter(({ id_fixed_asset }) =>
          this.filterdata.includes(id_fixed_asset.toLowerCase())
        );
      } else if (this.selectColum === "ชื่อสินทรัพย์") {
        if (this.listid.length == this.filterdata.length) {
          this.data = this.summaryData.allCountingItem;
        } else if (this.filterdata.length != 0) {
          // this.data = this.report;
          let arr = [];
          for (let i = 0; i < this.data.length; i++) {
            console.log(this.data[i]);
            for (let x = 0; x < this.filterdata.length; x++) {
              console.log(this.filterdata[x]);
              if (this.data[i].name === this.filterdata[x]) {
                arr.push(this.data[i]);
              }
            }
          }
          console.log("arr result >>>>", arr);
          this.data = arr;
        }
      }
    },
    async onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.url = URL.createObjectURL(file);
      this.upload();
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    blobToFile(theBlob, fileName) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    save(type) {
      if (!this.selectUpload) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        console.log("save", data);

        if (isEmpty) {
          alert("กรุณาเซ็นรับรอง");
          return;
        }

        let _blob = this.dataURItoBlob(data);
        console.log("blob ", _blob);
        let date = moment(new Date()).format("DDMMYYYY_HHmmss");
        let _file = this.blobToFile(_blob, `sign_${date}.png`);
        this.pictureFile = _file;
        console.log("file ", _file);
        console.log("pictureFile ", this.pictureFile.name);

        this.url = URL.createObjectURL(_file);
        console.log("url ", this.url);
        this.upload();
      }

      if (type === "sign") {
        this.$bvModal.hide("sign-modal");
        this.$bvModal.show("confirm-signature");
      }
      if (type === "sign-end") {
        this.$bvModal.hide("sign-end-modal");
        this.$bvModal.show("sign-end-confirm");
      }
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
    async upload() {
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      console.log("fddd", file);
      console.log("get user detail -> ", this.getUserDetail);
      this.isLoading = true;
      const res = await this.$store.dispatch("uploadImage", formdata);
      this.isLoading = false;
      console.log("res.data.data;", res.data.data);
      this.url_new = res.data.data.uploadFileAtLevel.url;
      console.log("url_new ", this.url_new);
      this.signSig.url = this.url_new;
      this.signSig.id_user = this.getUserDetail.id_user;
      this.signSig.role = this.userCompanyDetail.permission.role.name;
      this.signSig.department = this.signDepartment.label; // FIXME change to department ID
      console.log("waiting for confirm: ", this.signSig);
      // await axios
      //   .post("https://api.verscan.com/file/api/file/create", formdata, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     console.log("res.data.data;", res.data.data);
      //     this.url_new = res.data.data.uploadFileAtLevel.url;
      //     console.log("url_new ", this.url_new);
      //     this.signSig.url = this.url_new;
      //     this.signSig.id_user = this.getUserDetail.id_user;
      //     this.signSig.date = moment().format("YYYY-MM-DD hh:mm");
      //     this.signSig.role = this.userCompanyDetail.permission.role.name;
      //     this.signSig.department = this.signDepartment;
      //     console.log("waiting for confirm: ", this.signSig);
      //   });
    },
    async getSummaryData() {
      let filterStatus = "";

      if (
        this.currentFilterOptions.status === "ยังไม่ได้ตรวจนับ" ||
        this.currentFilterOptions.status === "ทั้งหมด"
      ) {
        filterStatus = null;
      } else {
        filterStatus = this.currentFilterOptions.status;
      }
      this.isLoading = true;
      if (this.currentFilterOptions.status === "ยังไม่ได้ตรวจนับ") {
        await this.$store.dispatch("getSummaryByStatus", {
          page: this.currentPage,
          limit: this.perPage,
          summary_id: this.$route.params.id_summary,
          status: filterStatus,
          filterOptions: this.currentFilterOptions,
          isVerified: false,
        });
      } else {
        await this.$store.dispatch("getSummaryByStatus", {
          page: this.currentPage,
          limit: this.perPage,
          summary_id: this.$route.params.id_summary,
          status: filterStatus,
          filterOptions: this.currentFilterOptions,
        });
      }
      this.isLoading = false;
    },
  },
  created() {
    // moment.locale("th");
    // this.$store.dispatch("getReportCount", 1);
    console.log("moment", moment(new Date()).format("dd DD-MMM-YYYY"));
    // console.log("this data >>>", this.data);
    // this.$root.$moment.locale("th");
  },
  watch: {
    summaryData() {
      this.isLoading = false;
      let data = this.summaryData.result.data.rows;
      let allArray = [];
      Object.keys(data).map(async (each) => {
        if (each !== "allAsset") {
          await allArray.push(...data[each]);
        }
      });
      this.data = allArray;
      console.log("data>>>>", data);
      this.startDate = new Date(this.summaryData.summary.counting_date)
        .toDateString()
        .split(" ");

      // this.startDate[3] = Number(this.startDate[3]);

      // Day
      switch (this.startDate[0]) {
        case "Sun":
          this.startDate[0] = this.dayNames[0];
          break;
        case "Mon":
          this.startDate[0] = this.dayNames[1];
          break;
        case "Tue":
          this.startDate[0] = this.dayNames[2];
          break;
        case "Wed":
          this.startDate[0] = this.dayNames[3];
          break;
        case "Thu":
          this.startDate[0] = this.dayNames[4];
          break;
        case "Fri":
          this.startDate[0] = this.dayNames[5];
          break;
        case "Sat":
          this.startDate[0] = this.dayNames[6];
      }
      // Month
      switch (this.startDate[1]) {
        case "Jan":
          this.startDate[1] = this.monthNames[0];
          break;
        case "Feb":
          this.startDate[1] = this.monthNames[1];
          break;
        case "Mar":
          this.startDate[1] = this.monthNames[2];
          break;
        case "Apr":
          this.startDate[1] = this.monthNames[3];
          break;
        case "May":
          this.startDate[1] = this.monthNames[4];
          break;
        case "Jun":
          this.startDate[1] = this.monthNames[5];
          break;
        case "Jul":
          this.startDate[1] = this.monthNames[6];
          break;
        case "Aug":
          this.startDate[1] = this.monthNames[7];
          break;
        case "Sep":
          this.startDate[1] = this.monthNames[8];
          break;
        case "Oct":
          this.startDate[1] = this.monthNames[9];
          break;
        case "Nov":
          this.startDate[1] = this.monthNames[10];
          break;
        case "Dec":
          this.startDate[1] = this.monthNames[11];
          break;
      }
      if (this.summaryData.summary.is_finish) {
        this.endDate = this.summaryData.summary.end_counting_date
          ? new Date(this.summaryData.summary.end_counting_date)
              .toDateString()
              .split(" ")
          : new Date(
              this.summaryData.summary.signatures.find((each) => {
                return (
                  each.role === "Executive (C Level)" ||
                  each.role === "Accounting Manager"
                );
              }).signed_date
            )
              .toDateString()
              .split(" ");
        // this.endDate[3] = Number(this.endDate[3]);

        switch (this.endDate[0]) {
          case "Sun":
            this.endDate[0] = this.dayNames[0];
            break;
          case "Mon":
            this.endDate[0] = this.dayNames[1];
            break;
          case "Tue":
            this.endDate[0] = this.dayNames[2];
            break;
          case "Wed":
            this.endDate[0] = this.dayNames[3];
            break;
          case "Thu":
            this.endDate[0] = this.dayNames[4];
            break;
          case "Fri":
            this.endDate[0] = this.dayNames[5];
            break;
          case "Sat":
            this.endDate[0] = this.dayNames[6];
        }
        // Month
        switch (this.endDate[1]) {
          case "Jan":
            this.endDate[1] = this.monthNames[0];
            break;
          case "Feb":
            this.endDate[1] = this.monthNames[1];
            break;
          case "Mar":
            this.endDate[1] = this.monthNames[2];
            break;
          case "Apr":
            this.endDate[1] = this.monthNames[3];
            break;
          case "May":
            this.endDate[1] = this.monthNames[4];
            break;
          case "Jun":
            this.endDate[1] = this.monthNames[5];
            break;
          case "Jul":
            this.endDate[1] = this.monthNames[6];
            break;
          case "Aug":
            this.endDate[1] = this.monthNames[7];
            break;
          case "Sep":
            this.endDate[1] = this.monthNames[8];
            break;
          case "Oct":
            this.endDate[1] = this.monthNames[9];
            break;
          case "Nov":
            this.endDate[1] = this.monthNames[10];
            break;
          case "Dec":
            this.endDate[1] = this.monthNames[11];
            break;
        }
      }
    },
    sortOrder() {
      this.sortData();
    },
    searchText() {
      if (!this.searchText) {
        this.clearSearch();
      } else {
        this.searchfunc();
      }
    },
    setDate() {
      this.arrCountingDate = this.summaryData.summary.counting_date;
      console.log("arr: ", this.arrCountingDate);
    },
    currentPage() {
      this.getSummaryData();
    },
  },

  async mounted() {
    this.$store.dispatch("getUser");
    await this.getSummaryData();
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.company_id = id_company;
    this.signDepartment = this.optionDepartment[1] || null;
    this.isLoading = false;

    // this.setDayMonthYear();
  },
  updated() {
    if (this.checked.id.length > 0) {
      this.isFilter = false;
    }
    if (this.checked.name.length > 0) {
      this.isFilter = false;
    }
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      report: "getReport",
      getUserDetail: "getUserDetail",
      summaryData: "getSummaryData",
      getSignature: "getSignature",
      getCountingDate: "getCountingDate",
      isSignConfirm: "isSignConfirm",
      companyName: "companyName",
      branchName: "branchName",
      departmentName: "departmentName",
      userCompanyDetail: "permissionStatus",
      isSignComplete: "isSignComplete",
      isRoleLower: "isRoleLower",
      optionChoice: "getfilterOption",
    }),
    report_asset() {
      return this.data;
    },
    upperSignDetail() {
      const department = this.jsonApprove.filter(
        (each) =>
          each.department == this.signDepartment.label &&
          (each.role === "Accounting Manager" ||
            each.role === "Account Officer" ||
            each.role === "Department Manager")
      );

      return department ? department : [];
    },
    lowerSignDetail() {
      const department = this.jsonApprove.filter(
        (each) =>
          each.department == this.value &&
          each.role !== "Accounting Manager" &&
          each.role !== "Account Officer" &&
          each.role !== "Department Manager" &&
          !each.is_end_user
      );

      return department ? department : [];
    },
    totalPages() {
      return Math.ceil(
        this.summaryData ? this.summaryData.result.data.count : 1
      );
    },
    checkDepartmentCountingEnd() {
      const departmentFilter = this.optionSignDepartment.filter(
        (department) => {
          if (
            this.jsonApprove.find((signature) => {
              return (
                signature.department === department &&
                (signature.role === "Department Manager" ||
                  signature.role === "Accounting Manager" ||
                  signature.role === "Account Officer")
              );
            })
          ) {
            return false;
          }
          return true;
        }
      );
      return departmentFilter;
    },

    endSignature() {
      const checkSignatureEnd = this.jsonApprove.find(
        (each) => each.is_end_user === true
      );
      return checkSignatureEnd;
    },

    checkNewDepartmentFeature() {
      // const check = this.jsonApprove.every((each) => each.department !== null);
      // const checkSignatureEnd = this.jsonApprove.find(
      //   (each) => each.is_end_user === true
      // );
      // return check || checkSignatureEnd;
      return true;
    },

    optionSignDepartment() {
      console.log(
        "this.summaryData.result.data",
        this.summaryData?.summary?.signatures
      );
      const signDepartmentList = [
        ...new Set(
          this.summaryData?.summary?.signatures?.map(
            (asset) => asset.department
          )
        ),
      ];

      return signDepartmentList;
    },

    datatable() {
      const assets = this.summaryData?.result?.data?.rows || [];
      return assets;
    },
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.currentFilterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.currentFilterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }

      // console.log("?????", this.getAllAssetsStore[0]);
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.currentFilterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.currentFilterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { id: null, label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ id: null, label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    searchCom() {
      let data = this.data;
      let searchText = this.searchText;
      if (searchText) {
        return (data = data.filter((asset) => {
          let searchName = asset.asset
            ? asset.asset.name.toLowerCase().includes(searchText.toLowerCase())
            : asset.name.toLowerCase().includes(searchText.toLowerCase());
          let searchCode = asset.asset
            ? asset.asset.id_fixed_asset
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : asset.id_fixed_asset
                .toLowerCase()
                .includes(searchText.toLowerCase());
          return searchName | searchCode;
        }));
      }
      return data;
    },
    jsonApprove() {
      if (this.summaryData.summary.signatures) {
        return typeof this.summaryData.summary.signatures == "string"
          ? JSON.parse(this.summaryData.summary.signatures)
          : JSON.parse(JSON.stringify(this.summaryData.summary.signatures));
      }
      return null;
    },

    listid() {
      let selectColum = this.selectColum;
      if (selectColum === "รหัสสินทรัพย์ถาวร") {
        let list = this.lodash.uniqBy(this.data, "id_fixed_asset");
        let searchList = this.searchList;
        console.log("list id fixed asset: ", list);
        return list.filter((asset) => {
          let searchName = asset.asset.id_fixed_asset
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      if (selectColum === "ชื่อสินทรัพย์") {
        let list = this.lodash.uniqBy(this.data, "name");
        let searchList = this.searchList;
        // console.log("list id name: ", list);
        return list.filter((asset) => {
          // console.log("asset",asset);
          let searchName = asset.asset.name
            .toLowerCase()
            .includes(searchList.toLowerCase());
          return searchName;
        });
      }
      return this.data;
    },
    selectAll: {
      get: function() {
        return this.report
          ? this.checked.id.length == this.report.length
          : false;
      },
      set: function(value) {
        var selected = [];
        let selectColum = this.selectColum;
        if (value) {
          if (selectColum === "รหัสสินทรัพย์ถาวร") {
            this.listid.forEach(function(report) {
              selected.push(report.id_fixed_asset);
            });
          }
          if (selectColum === "ชื่อสินทรัพย์") {
            this.listid.forEach(function(report) {
              selected.push(report.name);
            });
          }
          // this.report.forEach(function(report) {
          //   selected.push(report.id_fixed_asset);
          // });
        }
        if (selectColum === "รหัสสินทรัพย์ถาวร") {
          this.checked.id = selected;
        }
        if (selectColum === "ชื่อสินทรัพย์") {
          this.checked.name = selected;
        }
      },
    },
    totalpages() {
      return Math.ceil(this.summaryData?.result?.data?.count || 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-outline-primary :disabled {
  color: #fff;
}

#export-modal p {
  font-size: 18px;
}
.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  width: 130px;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}
.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.button-bar-group {
  overflow: auto;
  display: flex;
  width: 100%;
}
.form-check-scroll {
  height: 150px;
  overflow: scroll;
}
.filter-select {
  border-radius: 10px;
  border: 1px solid #bcbaba;
  padding: 0.4rem;
  width: 100% !important;
}
.btn-sort {
  background: #b9b9b9;
  width: 200px !important;
}
.btnsortafter {
  background: transparent linear-gradient(101deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.head-table {
  // width: 80px;
  text-align: center;
  margin: auto;
  font-size: 12px;
}

.button-bar {
  display: flex;
  justify-content: space-between;
  input {
    padding: 1rem;
    height: 35px;
    max-width: 400px;
  }
  button {
    font-size: 14px;
    margin: 0.1rem;
    width: fit-content;
    height: 45px;
    background: transparent;
    color: #007afe;
    border: solid;
    border-radius: 11px;
  }
  .button-load {
    min-width: max-content;
    padding: 5px;
    color: #007afe;
    border: 2px solid #007afe;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: transparent;
  }
}
.button-bar-filter {
  display: flex;
  float: right;
  button {
    font-size: 14px;
    margin: 0.1rem;
    margin-top: 1em;
    width: 120px;
    height: 35px;
    background: $font-grey;
    color: white;
    border: none;
    border-radius: 11px;
  }
  .button-load-filter {
    background: transparent linear-gradient(101deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    opacity: 0.5;
  }
  .buttonfilter {
    background-color: #cccccc;
  }
  .opa {
    background-color: #bcbaba;
    opacity: 0.5;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

// @media only screen and (max-width: 600px) {
//   .button-load {
//     font-size: 10px !important;
//   }
// }
@media only screen and (min-width: 320px) and (max-width: 370px) {
  .topic {
    font-size: 20px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 374px) and (max-width: 425px) {
  .topic {
    font-size: 24px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .topic {
    font-size: 24px;
  }
  .button-bar-group {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 955px) {
  .button-bar {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    input {
      padding: 1rem;
      height: 35px;
    }
  }
  .button-bar-group {
    display: flex;
    margin-bottom: 1rem;
    button {
      width: fit-content;
    }
  }
}

@media only screen and (max-width: 600px) {
  .button-bar-group {
    button {
      width: fit-content;
    }
  }
}

@media only screen and (max-width: 412px) {
  body,
  html,
  * {
    font-size: 14px;
  }
  .button-bar {
    //background: red;
    input {
      width: 65vw;
      padding: 0;
    }
  }
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

.drop-down-year {
  width: 100px;
  background-image: url("../assets/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding-right: 0.5rem;
}
.overflow-auto {
  overflow: auto;
}

.flex-header {
  display: flex;
  justify-content: space-between;
}

.searching {
  padding: 15px;
  // background-image: url("../image/search.svg");
  background-repeat: no-repeat;
  background-position: left;
  // padding: 0.5rem;
  &input {
    border: none !important;
    background-color: #e0e0e0;
  }
  &placeholder {
    padding-left: 1rem;
  }
}
.form-control {
  border-radius: 10px;
}

.header-text {
  font-size: 30px;
  font-weight: 400;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: white;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
}
.card-report {
  margin: auto;
  width: 95%;
  height: auto;
  background-color: white;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 2rem 1.5rem 2.5rem 1.5rem;
}

.card-div {
  margin: auto;
  width: 95vw;
  height: 50vh;
  /* background: linear-gradient(90deg, rgb(0, 123, 255), rgb(0, 62, 128)); */
}

.whiteBackground {
  background: white;
}
.high_29 {
  height: 29px !important;
}

.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid white;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 2px solid white;
}

.grid_button {
  display: flex;
  background: red;

  button {
    width: 150px !important;
  }
}

@media only screen and (min-width: 768px) {
  .grid_button {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    grid-template-rows: auto;
    background: pink;
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;
}

#signature {
  border: 2px solid #007afe;
  border-radius: 25px;
}

.banner {
  width: 100px;
}

.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px !important;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}
.btn-lost {
  font-size: 13px;
  padding: 0px !important;
}

.detail {
  text-align: center;
  font-size: 16px;
  color: #007afe;
}
.input-group {
  display: flex;
  padding: 0 !important;
  flex-wrap: nowrap;
}
#basic-addon1 {
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}
.end-counting {
  border: none !important;
  height: 47px;
  color: white !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
}
#sign-modal {
  label {
    color: #007afe;
  }
}
.department-remain:not(:last-child) {
  border-bottom: 1px solid grey;
}
.show-signature {
  width: 85%;
  border: 2px solid #007afe;
  border-radius: 25px;
  height: 200px;
  padding: 0;
  object-fit: cover;
}
.show-name {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.show-all-signature {
  max-height: 200px;
  max-width: 200px;
  width: 100%;
  height: 100%;
  border: 2px solid #007afe;
  border-radius: 10px;
}
.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
.dropdown-style {
  background-color: white;
  padding: 0 !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}
</style>
