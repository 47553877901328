<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Filter Option -->
    <b-modal
      id="filter-modal"
      @cancel="handleclear"
      :cancel-title="$t('btn.canc')"
      hide-header-close
      hide-header
      @ok="handlesearch"
      :ok-title="$t('btn.conf')"
    >
      <!-- @ok="handleFilter" -->
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>

      <label class="mt-2">{{ $t("home.build") }}</label>
      <v-select
        :options="optionBuilding"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.building"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("home.fl") }}</label>
      <v-select
        :options="optionFloor"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.floor"
        class="dropdown-style"
        :clearable="false"
      />

      <label class="mt-2">{{ $t("home.department") }}</label>
      <v-select
        :options="optionDepartment"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.department"
        class="dropdown-style"
        :clearable="false"
      />

      <label class="mt-2">{{ $t("home.zone") }}</label>
      <v-select
        :options="optionZone"
        label="label"
        :reduce="(option) => option.value"
        v-model="filterOptions.zone"
        class="dropdown-style"
        :clearable="false"
      />
    </b-modal>

    <div class="dashboard">
      <div class="block">
        <div class="back-icon">
          <img src="../assets/previous.svg" width="30" @click="gotoHistory()" />
        </div>
        <h1 class="header-text">{{ $t("dashboard.as_countre") }}</h1>

        <div v-if="progressStatus.is_show" class="progress_bar">
          <p>
            {{ $t("asset.importing") }}
            <nobr
              >{{ $t("asset.amount") }} {{ progressStatus.count }} /
              {{ progressStatus.max_assets }}
              {{ $t("asset.data_list") }}</nobr
            >
          </p>
        </div>

        <div
          class="inRemind"
          v-if="
            !progressStatus.is_show &&
              summaryData.summary.is_finish == false &&
              new Date(summaryData.summary.counting_date).getTime() <=
                Date.now()
          "
        >
          <p id="inReminder">{{ $t("dashboard.in_countpro") }}</p>
        </div>

        <div
          class="inRemind"
          v-if="
            summaryData.summary.is_finish == false &&
              new Date(summaryData.summary.counting_date).getTime() > Date.now()
          "
        >
          <p id="inReminder">
            {{ $t("dashboard.as_countnext") + startDate }}
          </p>
        </div>
        <div>
          <!-- {{ optionDate }} -->
          <div class="d-flex justify-content-center mt-2 mb-2 flex-column">
            <h5 class="col-12">
              {{ $t("dashboard.count_round") + startDate }}
            </h5>
            <h5 class="col-12" v-if="endDate" style="padding-left: 1rem">
              {{ $t("dashboard.end") + endDate }}
            </h5>
          </div>
          <div class="filter-input-group">
            <div class="d-flex justify-content-center align-items-center">
              <select
                class="dropdown_dashboard"
                style="margin-right: 10px; !important"
                v-model="filterOptions.date"
              >
                <option :value="null">{{ $t("dashboard.countdate") }}</option>
                <option
                  v-for="(list, index) in optionDate"
                  :key="index"
                  :value="list"
                >
                  {{ formatDate(list) }}
                </option>
              </select>
              <button
                class="filter"
                style="border: none"
                type="button"
                id="filter-button"
                @click="$bvModal.show('filter-modal')"
              >
                <!-- @click="filterShow = true" -->
                <img src="../assets/filter-white.svg" />
              </button>
            </div>
          </div>
          <!-- <p class="sub-label head-date">{{ startDate }}</p> -->
          <div style="max-width: 1300px; margin:auto;">
            <div id="chart" v-if="chartOptions.colors[0]">
              <apexchart
                type="donut"
                :options="chartOptions"
                :series="statusPie"
              ></apexchart>

              <div class="card-status">
                <p class="small-header">{{ $t("dashboard.all_fixedas") }}</p>
                <p class="sub-header">
                  <!-- {{ JSON.parse(summaryData.total_assets).length }} -->
                  <!-- {{ allStats.counted_item }}<span class="small-text">ชิ้น</span> / -->
                  {{ allAsset
                  }}<span class="small-text">{{ $t("dashboard.item") }}</span>
                </p>
              </div>
              <!-- seriesStat -->
            </div>
            <div
              style="
                height: 30vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-else
            >
              {{ $t("dashboard.no_count_ondate") }}
            </div>
            <div class="info_chart">
              <!-- <div class="info_chart_block_div"> -->
              <div
                v-for="(option, index) in labels"
                :key="index"
                class="info_chart_block d-flex justify-content-between"
              >
                <span
                  @click="gotoAllAsset(option.value)"
                  style="cursor: pointer"
                >
                  <span class="dots" :class="dotsClass[index]"> </span>
                  <span style="border-bottom: 1px solid #000">
                    {{ option.label }}
                  </span>
                </span>
                <span> {{ statusPie[index] }} {{ $t("dashboard.item") }} </span>
              </div>
              <!-- </div> -->

              <div class="footer-group biggest mt-4 d-none">
                <button
                  style="display: flex; justify-content: space-around; max-width: 500px"
                  class="btn btn-footer"
                  @click="gotoReportCount()"
                >
                  <img src="../assets/calendar-4.svg" />
                  <span style="padding: 15px 0">{{
                    $t("dashboard.sum_re")
                  }}</span>
                  <img
                    src="../assets/vector.svg"
                    style="padding: 20px 0"
                    width="10px"
                  />
                </button>
              </div>
              <b-container fluid class="reportTimeBiggest d-none">
                <b-row>
                  <b-col
                    cols="12"
                    class="justify-content-center text-grey d-flex align-items-sm-end"
                  >
                    <p style="margin-right: 0.5rem">{{ DateTimeReport }}</p>
                    <b-icon
                      icon="arrow-clockwise"
                      class="mt-2 mt-md-0 cursor-pointer"
                      font-scale="2"
                      @click="reload"
                    ></b-icon>
                  </b-col>
                </b-row>
                <b-row style="height: 7rem" class="margin-footer"></b-row>
              </b-container>
            </div>
          </div>
        </div>

        <!-- <div class="background-circle"></div>
        <div class="background-circle block"></div> -->
      </div>

      <div>
        <b-col cols="12" class="d-flex justify-content-center">
          <div class="footer-group">
            <button
              style="display: flex; justify-content: space-around; max-width: 500px"
              class="btn btn-footer"
              @click="gotoReportCount()"
            >
              <img src="../assets/calendar-4.svg" />
              <span style="padding: 15px 0">{{ $t("dashboard.sum_re") }}</span>
              <img
                src="../assets/vector.svg"
                style="padding: 20px 0"
                width="10px"
              />
            </button>

            <!-- <button
              style="display: flex; justify-content: space-around"
              class="btn btn-footer"
              @click="gotoDocRegis()"
              v-if="isImportCookie"
            >
              <img src="../assets/paper-2.svg" />
              <span style="padding: 15px 0">{{
                $t("dashboard.as_regdoc")
              }}</span>
              <img
                src="../assets/vector.svg"
                style="padding: 20px 0"
                width="10px"
              />
            </button> -->
          </div>
        </b-col>
      </div>
      <b-container fluid class="reportTime">
        <b-row>
          <b-col
            cols="12"
            class="justify-content-center text-grey d-flex align-items-sm-end"
          >
            <p style="margin-right: 0.5rem">{{ DateTimeReport }}</p>
            <b-icon
              icon="arrow-clockwise"
              class="mt-2 mt-md-0 cursor-pointer"
              font-scale="2"
              @click="reload"
            ></b-icon>
          </b-col>
        </b-row>
        <b-row style="height: 7rem" class="margin-footer"></b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import axios from "axios";
import { stage } from "../util/backend";
import Cookie from "js-cookie";

export default {
  data() {
    return {
      availablePackages: null,
      ALL_LABELS: [
        {
          label: this.$t("count.inuse"),
          value: "available",
          key: "usable_item",
        },
        {
          label: this.$t("count.not_inuse"),
          value: "unavailable",
          key: "unusable_item",
        },
        { label: this.$t("count.broke"), value: "broke", key: "broke" },
        {
          label: this.$t("count.repairing"),
          value: "maintain",
          key: "maintain",
        },
        {
          label: this.$t("count.irrepar"),
          value: "cannot_repair",
          key: "unfixable",
        },
        { label: this.$t("count.lost"), value: "lost", key: "lost" },
        {
          label: this.$t("count.not_count"),
          value: "uncounting",
          key: "notCounting",
        },
        {
          label: this.$t("count.not_reg"),
          value: "unregister",
          key: "unregis",
        },
      ],
      labels: [],
      timer: null,
      id_company: null,
      progressStatus: {
        timestamp: null,
        max_assets: null,
        username: null,
        count: null,
        is_show: false,
      },
      isShowProgressBar: true,

      isLoading: true,
      summaryDetail: [],
      all_status: [],
      startDate: null,
      endDate: null,
      optionDate: null,
      filterOptions: {
        date: null,
        building: null,
        floor: null,
        department: null,
        zone: null,
      },
      dotsClass: null,
      series: [2, 90, 70, 45, 10, 5, 130],

      chartOptions: {
        chart: {
          type: "donut",
          events: {
            legendClick: function(series) {
              return series;
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return parseInt(val) + `%`;
          },
          enabledOnSeries: undefined,
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Mitr, sans-serif",
            fontWeight: "bold",
            colors: ["#FFF"],
          },
        },
        colors: [
          //  "#00AB1B",
          // "#01B1FD",
          // "#FF8A00",
          // "#C0C0FA",
          // "#846363",
          // "#EF8A79",
          // "#FFE663",
          // "#C4C4C4",
        ],
        labels: [],
        tooltip: {
          y: {
            formatter: function(value) {
              return (
                value +
                `${
                  localStorage.getItem("locale") === "en" ? " item(s)" : " ชิ้น"
                }`
              );
            },
          },
          enabled: true,
          shared: true,
          enabledOnSeries: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: undefined,
          theme: "light",
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          marker: {
            show: true,
          },
          items: {
            display: `flex`,
          },
          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        legend: {
          show: false,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Mitr, sans-serif",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: function(seriesName, opts) {
            return (
              seriesName +
              " - <strong>" +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              "</strong>"
            );
          },
          offsetX: 10,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",

            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 20,
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        responsive: [
          {
            breakpoint: 2999,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "right",
              },
            },
          },

          {
            breakpoint: 1100,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },

          {
            breakpoint: 760,
            options: {
              chart: {
                width: 220,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      // fill: { gradient: ["#007BFF", "#003E80"] },
      onClick: true,
    };
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
    clearInterval(this.timer);
  },
  async created() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.id_company = id_company;
    await this.getProgressBar(true);
    this.timer = setInterval(() => {
      this.getProgressBar(false);
    }, 3000);
  },

  async mounted() {
    this.setFilterFromStore();
    await this.$store.dispatch("getSummaryById_pagination", {
      id_summary: this.$route.params.id_summary,
      filterOptions: this.filterOptions,
    });
    await this.$store.dispatch("getAllCountingDateByIdCompany");

    let getCookie = Cookie.get("selectedcompany");
    if (getCookie) {
      let companyData = JSON.parse(getCookie);
      this.availablePackages = companyData.package;
    }
    this.availablePack();
    this.dotClassColor();
  },

  computed: {
    ...mapGetters({
      getCountingDate: "getCountingDate",
      summaryData: "getSummaryData",
      getUserDetail: "getUserDetail",
      isCheckResult: "isCheckResult",
      isImportCookie: "isImport",
      getAllAssets: "getAllAssets",
      optionChoice: "getfilterOption",
      getDashboardModalFilter: "getDashboardModalFilter",
    }),

    DateTimeReport() {
      return moment().format(
        `${this.$t("dashboard.report_on")} dddd ${this.$t(
          "dashboard.at"
        )} DD MMMM YYYY [${this.$t("dashboard.time")}] HH:mm`
      );
    },
    statusPie() {
      const pie = this.labels.map((option) => {
        if (this.availablePackages.maintenance) {
          return this.all_status[option.key];
        } else if (!this.availablePackages.maintenance) {
          if (
            option.key == "broke" ||
            option.key == "unfixable" ||
            option.key == "maintain"
          ) {
            let a =
              this.all_status["broke"] +
              this.all_status["unfixable"] +
              this.all_status["maintain"];
            return a;
          }
          return this.all_status[option.key];
        }
      });
      console.log(pie);
      return pie;
    },

    allAsset() {
      return this.summaryData?.result?.total?.allItems || 0;
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.name,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice?.optionFloor
              .filter((item) => {
                let building = this.optionChoice?.optionBuilding.find(
                  (building) => building.name == this.filterOptions.building
                );
                if (
                  building?.id_location_building == item.id_location_building
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((each) => {
                return { label: each.name, value: each.name };
              })
          ),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { id: null, label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                id: item.id_location_department,
                label: `${item.code} - ${item.name}`,
                value: item.name,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ id: null, label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.name,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  watch: {
    "filterOptions.date": async function(val) {
      await this.$store.dispatch("getSummaryById_pagination", {
        id_summary: this.$route.params.id_summary,
        filterOptions: this.filterOptions,
      });
    },
    summaryData() {
      // this.startDate = new Date(
      //   this.summaryData.summary.counting_date
      // ).toDateString();
      this.startDate = moment(this.summaryData.summary.counting_date).format(
        `dddd ${this.$t("dashboard.at")} DD MMMM YYYY`
      );

      if (this.summaryData.summary.is_finish) {
        const endingDate = this.summaryData.summary.end_counting_date
          ? new Date(this.summaryData.summary.end_counting_date)
          : new Date(
              this.summaryData.summary.signatures.find((each) => {
                return (
                  each.role === "Executive (C Level)" ||
                  each.role === "Accounting Manager"
                );
              }).signed_date
            );

        this.endDate = moment(endingDate).format(
          `dddd ${this.$t("dashboard.at")} DD MMMM YYYY`
        );
      }
      this.summaryDetail = this.summaryData;
      this.all_status = {
        all_assets: this.summaryData.result.total.allItems,
        usable_item: this.summaryData.result.total.canUse,
        unusable_item: this.summaryData.result.total.notUse,
        unfixable: this.summaryData.result.total.cannotRepair,
        lost: this.summaryData.result.total.lost,
        notCounting: this.summaryData.result.total.notCounting,
        unregis: this.summaryData.result.total.unRegis,
        maintain: this.summaryData.result.total.maintain,
        broke: this.summaryData.result.total.broke,
        other: this.summaryData.result.total.other,
      };
      console.log(this.all_status);
      this.isLoading = false;
    },
  },
  methods: {
    dotClassColor() {
      if (this.availablePackages.maintenance) {
        this.dotsClass = [
          "dots_green",
          "dots_blue",
          "dots_orange",
          "dots_purple",
          "dots_brown",
          "dots_red",
          "dots_yellow",
          "dots_grey",
        ];
        this.chartOptions.colors = [
          "#00AB1B",
          "#01B1FD",
          "#FF8A00",
          "#C0C0FA",
          "#846363",
          "#EF8A79",
          "#FFE663",
          "#C4C4C4",
        ];
      } else {
        this.chartOptions.colors = [
          "#00AB1B",
          "#01B1FD",
          "#FF8A00",
          "#EF8A79",
          "#FFE663",
          "#C4C4C4",
        ];
        this.dotsClass = [
          "dots_green",
          "dots_blue",
          "dots_orange",
          "dots_red",
          "dots_yellow",
          "dots_grey",
        ];
      }
    },

    availablePack() {
      if (this.availablePackages.maintenance) {
        this.labels = this.ALL_LABELS;
        this.chartOptions.labels = this.ALL_LABELS.map((label) => label.label);
      } else if (!this.availablePackages.maintenance) {
        this.labels = this.ALL_LABELS.filter(
          (label) =>
            label.value !== "maintain" && label.value !== "cannot_repair"
        );
        this.chartOptions.labels = this.labels.map((label) => label.label);
      }
    },
    setFilterFromStore() {
      if (this.getDashboardModalFilter) {
        this.filterOptions.building = this.getDashboardModalFilter.building
          ? this.getDashboardModalFilter.building
          : null;
        this.filterOptions.floor = this.getDashboardModalFilter.floor
          ? this.getDashboardModalFilter.floor
          : null;
        this.filterOptions.department = this.getDashboardModalFilter.department
          ? this.getDashboardModalFilter.department
          : null;
        this.filterOptions.zone = this.getDashboardModalFilter.zone
          ? this.getDashboardModalFilter.zone
          : null;
      }
    },
    async handleclear() {
      this.filterOptions = {
        date: null,
        building: null,
        floor: null,
        department: null,
        zone: null,
      };
      await this.$store.dispatch("getSummaryById_pagination", {
        id_summary: this.$route.params.id_summary,
        filterOptions: this.filterOptions,
      });
    },
    async handlesearch() {
      if (
        this.filterOptions.date ||
        this.filterOptions.building ||
        this.filterOptions.floor ||
        this.filterOptions.department ||
        this.filterOptions.zone
      ) {
        await this.$store.dispatch("getSummaryById_pagination", {
          id_summary: this.$route.params.id_summary,
          filterOptions: this.filterOptions,
        });
      } else {
        await this.$store.dispatch("getSummaryById_pagination", {
          id_summary: this.$route.params.id_summary,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    async gotoAllAsset(label) {
      this.$router.push(`/dashboard/${this.$route.params.id_summary}/${label}`);
    },

    changeValue() {
      this.series[0] = 500;
    },

    gotoReportCount() {
      this.$router.push(`/ReportCount/${this.$route.params.id_summary}`);
    },
    // gotoDocRegis() {
    //   this.$router.push(`/docRegisAsset/${this.$route.params.id_summary}`);
    // },
    gotoHistory() {
      this.$router.push("/history");
    },
    eventClick() {
      this.onClick = !this.onClick;
    },
    reload() {
      window.location.reload();
    },

    async getProgressBar(isFirstTime) {
      // console.log("timer", this.timer);
      let type = 2;
      let env = stage();
      try {
        const res = await axios.get(
          `https://api.verscan.com/process_counter/get_process_counter.php?company_id=${this.id_company}&env=${env}&process_type=${type}`
        );
        let { data } = res;
        if (data.current_counting && data.targeted_count) {
          if (+data.current_counting >= +data.targeted_count) {
            this.progressStatus.count = parseInt(
              data.current_counting
            ).toLocaleString();
            this.progressStatus.max_assets = parseInt(
              data.targeted_count
            ).toLocaleString();

            if (isFirstTime) {
              this.progressStatus.is_show = false;
            }

            clearInterval(this.timer);
          } else {
            this.progressStatus.is_show = true;
            this.progressStatus.count = parseInt(
              data.current_counting
            ).toLocaleString();
            this.progressStatus.max_assets = parseInt(
              data.targeted_count
            ).toLocaleString();
          }
        } else {
          clearInterval(this.timer);
        }
      } catch (err) {
        console.log(err);
        clearInterval(this.timer);
        this.progressStatus.is_show = false;
      }
    },
  },
  components: { Footer, apexchart: VueApexCharts, Loading },
};
</script>

<style scoped lang="scss">
.real-time {
  padding-bottom: 30%;
}
body {
  background: transparent linear-gradient(155deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}
.dropdown_dashboard {
  display: flex;
  background-color: white;
  height: 45px;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: window;
  padding: 2px 1rem 2px 1rem;
  background-image: url("../assets/down-arrow-black.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding-right: 0.5rem;
  border: 1px solid #d0d0d0;
  border-radius: 11px;
  text-align: left;
}

.choose_year {
  padding: 0.2rem 1rem 0.2rem 1rem;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 11px;
  opacity: 1;
}
.dots {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #c4e84a 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  &_green {
    background: #00ab1b;
  }
  &_blue {
    background: #01b1fd;
  }
  &_orange {
    background: #ff9c15;
  }
  &_purple {
    background: #c0c0fa;
  }
  &_brown {
    background: #846363;
  }
  &_red {
    background: #ef8a79;
  }
  &_yellow {
    background: #ffe663;
  }
  &_grey {
    background: #c4c4c4;
  }
  &_pink {
    background: $color-pastel-pink;
  }
  &_darkBlue {
    background: #5272e2;
  }
}
.info_chart {
  font-size: 14px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  gap: 10px;
  padding: 0px 20px 0px 20px;
  position: relative;
}

.info_chart_block {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  width: 100%;
  justify-items: start;
}
.sub-label {
  font-size: 18px;
  margin: auto;
  text-align: center;
  padding: 0 !important;
}
#chart {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.process-circle-custom {
  color: black;
  font-weight: 400;
  border-radius: 50%;
  box-shadow: 1px 3px 10px #00000029;
  height: 180px;
}
p {
  margin-bottom: 0 !important;
}
.footer-last {
  margin: auto;
  margin-top: 0.5rem;
  width: 90% !important;
}
.footer-group {
  margin: 0 auto 50px auto;
  display: flex;
  justify-content: center;
  margin-top: 0;
  // display: inline;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  width: 90%;
  height: 90%;
  & img {
    text-align: center;
    padding: 0.3rem;
  }
}
.footer-group-employee {
  margin: auto;
  margin-top: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  justify-items: center;
  width: 90%;
  height: 90%;
  & img {
    text-align: center;
    padding: 0.3rem;
  }
}

.btn-footer {
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  font-size: 18px;
  // background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  // background: -webkit-gradient(linear, #007afe 0%, #013f81 100%);
  color: #013f81;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  margin-top: 25px;

  &-orange {
    margin-top: 1rem;
    background: transparent linear-gradient(100deg, #fcf7b8 0%, #f7ac54 100%) 0%
      0% no-repeat padding-box;
  }
  z-index: 1000000;
}

.status-bar-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 0.2rem;

  &-orange {
    background-color: #ff9c15;
  }

  &-yellow {
    background-color: #ffe886;
  }

  &-green {
    background-color: #c4e84a;
  }

  &-pink {
    background-color: #feceab;
  }
  &-dark-pink {
    background-color: #fa8179;
  }

  &-red {
    background-color: #c61414;
  }
}
.status-bar-progress {
  display: flex;
  justify-content: space-between;

  &-detail {
    display: flex;

    padding-top: 0.4rem;
    font-size: 0.7rem;
  }
}
.bar-progress {
  margin-top: 5px;
  width: 100%;
  height: 24px;
  box-shadow: 0px 2px 3px #0000001a;
  border-radius: 20px;

  background: #e0e0e0 0% 0% no-repeat padding-box;

  &-orange {
    @extend .bar-progress;
    width: 60%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    background-color: #ff9c15;
  }

  &-yellow {
    @extend .bar-progress;
    width: 100%;
    background-color: #ffe886;
  }

  &-green {
    @extend .bar-progress;
    width: 60%;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;

    background-color: #c4e84a;
  }

  &-red {
    @extend .bar-progress;
    width: 30%;

    background-color: #c61414;
  }

  &-pink {
    @extend .bar-progress;

    background-color: #feceab;
  }
  &-dark-pink {
    @extend .bar-progress;
    width: 60%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #fa8179;
  }
}

.circle-prgress {
  box-sizing: border-box;
  margin: auto;
  width: 58%;
  height: 55%;
  border: 12px solid #e5f0fc;
  box-shadow: 2px 3px 10px #00000029;

  border-radius: 50%;

  &-inline {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 12px solid #e5f0fc;
  }
}

.card-status {
  // width: 90%;
  height: auto;
  // margin: auto;
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  // margin-bottom: 1rem;
  // box-shadow: 0px 0px 10px #00000033;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.small-text {
  color: #4b4b4b;
  font-size: 0.75rem;
}

.small-header {
  font-size: 14px;
  padding-bottom: 0.5rem;
}
.sub-header {
  max-width: 100%;
  font-size: 3rem;
  color: black;
}
@media only screen and (max-width: 330px) {
  .sub-header {
    font-size: 2rem;
    color: black;
  }
}

/* @media only screen and (max-width: 375px) and (max-height: 820px) {
  .block {
    height: 50vh !important;
  }

  .background-circle {
    height: 100vh !important;
  }
} */

.block {
  position: relative;
  height: fit-content;
  // overflow: hidden;
  margin-bottom: 20px;
}

.background-circle {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translateX(-50%);
  width: 210vw;
  background: white;
  border-radius: 50%;
  top: -50vh;
  z-index: -100;
}
@media only screen and (max-width: 320px) {
  .btn-footer {
    align-items: center;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 320px) and (min-height: 568px) {
  .info_chart {
    font-size: 11px !important;
    align-items: center;
  }
  .info_chart_block {
    align-items: end;
  }
  .background-circle {
    height: 800px;
  }
}

@media only screen and (min-width: 320px) and (min-height: 640px) {
  .background-circle {
    height: 820px;
  }
}

@media only screen and (min-width: 375px) and (min-height: 667px) {
  .info_chart_block {
    align-items: end;
  }
  .background-circle {
    height: 870px;
  }
}

@media only screen and (min-width: 375px) and (min-height: 812px) {
  .background-circle {
    height: 910px;
  }
}

@media only screen and (min-width: 411px) and (min-height: 823px) {
  .background-circle {
    height: 920px;
  }
}

// iphone  11
@media only screen and (min-width: 414px) {
  .background-circle {
    height: 950px;
  }
}

@media only screen and (min-width: 768px) {
  .btn-footer {
    // margin-top: 100px;
    width: 85%;
  }

  .footer-group {
    display: flex;
    justify-content: center;
    margin: 0px auto 30px auto;
  }
  .info_chart {
    grid-template-columns: 240px 240px;
    justify-content: center;
  }
  .background-circle {
    height: 1135px;
  }
}

@media only screen and (min-width: 1025px) {
  .margin-footer {
    display: none;
  }
  .info_chart {
    font-size: 17px !important;
    z-index: 1;
  }
  .background-circle {
    height: 1200px;
  }

  .grid-responsive {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .responsive-bar {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  body {
    background: grey;
  }
  .background-circle {
    display: none;
  }
  .grid-responsive {
    background: white;
    width: 900px;
    margin: auto;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 91px;

    &-custom {
      margin-top: -1.5rem;
      background: none;
      box-shadow: none;
    }
  }
  .header-text {
    color: white;
  }

  #chart {
    margin-top: 40px;
    // margin-left: 60px;
    // justify-content: start;
    // float: left;
  }

  .card-status {
    padding: 60px 1.5rem;
  }

  .info_chart {
    justify-content: end;
    display: block;
    width: 40%;
    // float: right;
    padding: 20px 20px;
  }

  .info_chart_block {
    margin-bottom: 10px;
  }

  .footer-group {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .btn-footer {
    margin: 0px 30px;
  }
}
.circle-status {
  position: absolute;
  width: 50%;
  height: 30%;
  background: black;
}

.onClickLabel {
  border-bottom: 3px solid #0069d9;
  color: #0069d9;
}
.sub-menu {
  color: grey;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
  p {
    cursor: pointer;
  }
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.btn-upload {
  margin-top: 2rem;
}

.detail {
  font-size: 11px;
  color: #7c7c7c;
}

.card-form {
  width: 80%;
  padding: 2.5rem;
  padding-bottom: 2rem;
}

.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (min-width: 1450px) {
  #chart {
    margin-left: 40px;
    margin-top: 70px;
    justify-content: center !important;
    float: left;
  }
  .info_chart {
    justify-content: end;
    display: block;
    width: 560px;
    float: right;
    padding: 40px 20px;
    margin-right: 60px;
  }

  .info_chart_block {
    margin: 10px auto;
    width: 80%;
    justify-items: center !important;
  }

  .footer-group {
    display: none !important;
    &.biggest {
      display: block !important;
      width: 100%;
    }
  }
  .reportTimeBiggest {
    padding-left: 20px;
    display: block !important;
  }
  .reportTime {
    display: none !important;
  }

  .card-status {
    padding: 90px 1.5rem;
  }

  .btn-footer {
    margin: 0px auto;
  }
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}

.dashboard {
  // width: 100%;
  // height: 100%;
  position: relative;
  z-index: 2;
  // overflow: scroll;
}

.inRemind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #d2e2f8;
  padding-bottom: 5px;
}

#inReminder {
  font-size: 18px;
  margin: auto;
  color: #434343;
}

.head-date {
  padding-top: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  // text-decoration: underline;
}

#filter-button {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.progress_bar {
  padding: 0.5rem;
  background-color: #fff5d0;
}
</style>
