<template>
  <div class="responsive-bar">
    <Footer></Footer>

    <div class="register">
      <!-- <Background></Background> -->
      <div class="back-icon">
        <img src="../assets/previous.svg" width="30" @click="backtoProfile()" />
      </div>

      <!-- <div v-if="prevRoute.name == 'ChangePassword'">
        <SuccessDialog
          :msg="$t('dialog.di_pw')"
          v-show="showSuccess"
        ></SuccessDialog>
      </div> -->
      <SuccessDialog
        :msg="$t('dialog.save_edit')"
        v-show="showSuccess"
      ></SuccessDialog>
      <div class="">
        <h1 class="header-text">
          <div class="topic">{{ $t("profile.edit_us") }}</div>
        </h1>
        <div class="frame-profile">
          <img v-if="url_new" :src="url_new" class="banner-profile" />
          <img v-else class="banner" :src="getUserDetail.image_url" alt="" />

          <div class="upload-icon-plus">
            <label for="fusk">
              <img src="../assets/pencil-round.svg" style="cursor: pointer" />
            </label>
            <input
              id="fusk"
              type="file"
              accept="image/png, image/jpeg"
              name="photo"
              style="display: none; margin: 0px !important"
              @change="onFileChange($event)"
            />
          </div>
        </div>

        <div class="card-form-login">
          <form>
            <div class="input-group">
              <label>{{ $t("profile.name") }}</label>

              <input type="text" v-model="getUserDetail.name" />
            </div>

            <div class="input-group">
              <label>{{ $t("profile.email") }}</label>
              <input type="email" v-model="getUserDetail.email" disabled />
            </div>

            <div class="input-group">
              <label>{{ $t("profile.pw") }}</label>
              <input type="password" v-model="lock" disabled />
              <small
                class="sub-text"
                @click="gotoChangePass()"
                style="cursor: pointer"
                >{{ $t("profile.ch_pw") }}</small
              >
            </div>

            <div class="input-group">
              <label>{{ $t("profile.phone") }}</label>
              <input
                type="tel"
                pattern="[0-9]+"
                title="Please enter exactly 10 digits"
                v-model="getUserDetail.phone"
                @input="replaceNumber"
              />
            </div>

            <div class="input-group">
              <label>{{ $t("profile.permission") }}</label>
              <input type="text" v-model="getUserDetail.role" disabled />
            </div>

            <div class="input-group">
              <label>{{ $t("profile.pos") }}</label>
              <input type="text" v-model="getUserDetail.position_company" />
            </div>

            <div class="input-group" style="padding-bottom: 1rem">
              <label>{{ $t("profile.dep") }} </label>
              <!-- <input
                type="text"
                v-model="getUserDetail.department"
                :disabled="getUserDetail.is_payer"
              /> -->
              <input type="text" v-model="getUserDetail.department" />
            </div>
            <button
              class="btn btn-login"
              type="button"
              @click="gotoProfile()"
              :disabled="isLoading"
            >
              {{ $t("profile.save_edit") }}
            </button>
          </form>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import Axios from "axios";
import Compressor from "compressorjs";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import replacePhone from "../util/replacePhoneNumber";

export default {
  data() {
    return {
      isLoading: false,
      url: null,
      url_new: null,
      pictureFile: "",
      lock: "12345678",
      prevRoute: null,
      showSuccess: false,
      user_data: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    async onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      this.url = URL.createObjectURL(file);
      this.upload();
    },
    replaceNumber() {
      this.getUserDetail.phone = replacePhone(this.getUserDetail.phone);
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.2,
          success(result) {
            resolve(result);
            console.log("compressed success");
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      const fileforupload = await this.compressImage(this.pictureFile);
      var file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      var formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      // console.log(`fileforupload`, fileforupload);
      console.log("fddd", file);
      const res = await this.$store.dispatch("uploadImage", formdata);
      this.url_new = res.data.data.uploadFileAtLevel.url;
      this.getUserDetail.image_url = this.url_new;
      console.log("new user image: ", this.getUserDetail.image_url);
      // Axios.post("https://api.verscan.com/file/api/file/create", formdata, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // }).then((res) => {
      //   this.url_new = res.data.data.uploadFileAtLevel.url;
      //   // console.log("url_new ", this.url_new);
      //   this.getUserDetail.image_url = this.url_new;
      //   console.log("new user image: ", this.getUserDetail.image_url);
      //   // console.log("res.data.data;", res.data.data);
      //   // fd.append("image_url", this.url_new);
      //   //this.img1 = res.data.data; // แก้ตรงนี้
      // });
    },

    async gotoProfile() {
      console.log("edit profile: ", this.getUserDetail);
      this.isLoading = true;
      try {
        await this.$store.dispatch("editUser", this.getUserDetail);
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
          this.isLoading = false;
          this.$router.back();
        }, 2000);
      } catch (err) {
        alert(err);
      }
    },
    gotoChangePass() {
      this.$router.push("/changePassword");
    },
    backtoProfile() {
      this.$router.push("/profile");
    },
  },
  computed: {
    ...mapGetters({
      // getLogin: "getLogin",
      getUserDetail: "getUserDetail",
    }),
  },
  mounted() {
    
    if (!this.getUserDetail) {
      this.$router.replace("/profile");
    }
  },
  components: { Footer, SuccessDialog },
};
</script>

<style lang="scss" scoped>
.banner-profile {
  height: 90%;
  width: 90%;
  border-radius: 50%;
  object-fit: cover;
}

input {
  padding-left: 15px;
  border: 0px;
  // font-weight: bold;
}

input[type="password"]:disabled {
  background: #e0e0e0;
  border-radius: 6px;
}

input[type="text"]:disabled {
  background: white;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  background: #e0e0e0;
  border-radius: 6px;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-right: -1px;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.sub-text {
  text-align: right;
  font: normal normal normal 14px/18px Mitr;
  letter-spacing: 0px;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
  margin-left: auto;
  margin-top: 2px;
}

.small {
  display: flex;
  justify-content: right;
}

form {
  padding: 0.5rem;
}
.frame-profile {
  position: absolute;
  // top: -0.2rem;
  top: 173px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  /* text-align: center; */
  margin: auto;
  background: white;
  border-radius: 50%;
  border: 5px solid white;
  // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
}

.upload-icon-plus {
  position: absolute;
  bottom: 30%;
  right: 2%;
  z-index: 10;
  img {
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 30%;
    width: 31px;
    height: 31px;
  }
}
.upload-icon-content {
  display: flex;
  font-size: 4rem;
  align-items: center;
}
.banner {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 1;
}
.register {
  position: relative;
  z-index: 2;
}
.btn-login {
  margin-top: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.card-form-login {
  margin-top: 60px;
  margin-left: 5%;
  margin-right: 5%;
}

.input-group {
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (min-width: 1025px) {
  .card-form-login {
    margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
</style>
