import { UserRoles } from "../constants/UserRoles";

export const getters = {
  // ------------------- for maintenance mode ------------------------
  isInMaintenanceMode: () => false,
  // ------------------- for maintenance mode ------------------------
  isNativeApp: () => window.isNativeApp,
  isTester: (state) => state.user.is_tester,

  getCustomName: (state) => state.customName,
  getQrGen: (state) => state.qr_all_gen,
  getAsset: (state) => state.asset,
  getRegis: (state) => state.result_regis_asset,
  getAllAssetsStore: (state) => state.all_asset_store,
  getAllAssets: (state) => state.all_asset,
  getAllAssetsFilter: (state) => state.asset_filter,
  getManageAssetFilter: (state) => state.manage_asset_filter,
  getAllAssetOtherColumn: (state) => state.asset_other_column,
  getCheckAsset: (state) => state.check_asset,
  getCreateCheckAsset: (state) => state.create_check_asset,
  getReport: (state) => state.report_asset,
  getDetailAsset: (state) => state.detailAsset,
  getAssetSelectedRegister: (state) => state.assetSelectedRegister,
  getUserRegister: (state) => state.user_register,
  getLogin: (state) => state.login,
  getUserDetail: (state) => state.user,
  getEditUserDetail: (state) => state.editUser,
  getSelectedUserDetail: (state) => state.selected_user,
  getAllUserDetail: (state) => state.all_user,
  getAssetAmount: (state) => state.asset_amount,
  getAssetUsed: (state) => state.asset_used,
  getAssetDamaged: (state) => state.asset_damaged,
  getAllSubmary: (state) => state.all_sumary,
  getMapUrl: (state) => state.map_url,
  getMapPosition: (state) => state.map_position,
  getAllMap: (state) => state.all_map,
  getFloor: (state) => state.floor,
  getCountFloor: (state) => state.count_floor,
  getRegisAsset: (state) => state.regis_asset,
  getRegisAssetResult: (state) => state.regis_asset_result,
  getRegisAssetCheck: (state) => state.regis_asset_check,
  getCountingDate: (state) => state.counting_date,
  getSummaryData: (state) => state.summary_data,
  getCompanyDetail: (state) => state.company_detail,
  getAdminPage: (state) => state.admin_page,
  getSignature: (state) => state.signature,
  getHashID: (state) => state.hash_id,
  getDashboardFilter: (state) => state.dashboard_filter,
  getDashboardModalFilter: (state) => state.dashboard_modal_filter,
  isAlert: (state) => state.isAlert,
  permissionStatus: (state) => state.permissionStatus,

  // ------------------- START for displaying custom function ------------------------
  canUseCustom1: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 1) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom2: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 2) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom3: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 3) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom4: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 4) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom5: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 5) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom6: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 6) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom7: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 7) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom8: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 8) {
      return true;
    } else {
      return false;
    }
  },
  canUseCustom9: (state) => {
    const customId = state.permissionStatus?.company?.custom_id;
    if (customId === 9) {
      return true;
    } else {
      return false;
    }
  },
  // ------------------- END  for displaying custom function ------------------------
  // ------------------- START for block user from accessing DP function ------------------------
  isFreeTrial: (state) =>
    state.permissionStatus?.package?.package_name === "free trial"
      ? true
      : false,
  // canUseDepreciation: (state, getters) =>true,
  canUseDepreciation: (state, getters) =>
    !!state.permissionStatus?.package?.depreciation &&
    [
      UserRoles.EXCLUSIVE,
      UserRoles.ACC_MANAGER,
      UserRoles.ACC_OFFICER,
    ].includes(state.permissionStatus.permission.role.id_role),
  // ------------------- END for block user from accessing DP function ------------------------
  // ------------------- START for block user from accessing Borrow Return function ------------------------
  canUseBorrowReturn: (state) =>
    !!state.permissionStatus?.package?.borrowreturn,
  isBorrowReturnAdmin: (state) => {
    return [UserRoles.ACC_MANAGER, UserRoles.WAREHOUSE_OFFICER].includes(
      state.permissionStatus.permission.role.id_role
    );
  },
  // ------------------- END for block user from accessing Borrow Return function ------------------------
  // ------------------- START for block user from accessing Maintenance function ------------------------
  canUseMaintenanceReport: (state) =>
    !!state.permissionStatus?.package?.maintenance,
  // ------------------- END for block user from accessing Maintenance function ------------------------
  companyName: (state) => state.permissionStatus.company.name_company,
  branchName: (state) => state.permissionStatus.company.branch_name,
  departmentName: (state) => state.permissionStatus.branch_name,
  isImport: (state) => state.permissionStatus.permission.import,
  isManage: (state) => state.permissionStatus.permission.manage,
  isReportFound: (state) => state.permissionStatus.permission.report_found,
  isReportLost: (state) => state.permissionStatus.permission.report_lost,
  isSignComplete: (state) => state.permissionStatus.permission.sign_complete,
  isSignConfirm: (state) => state.permissionStatus.permission.sign_confirm,
  isScan: (state) => state.permissionStatus.permission.scan,
  isPayer: (state) => state.permissionStatus.is_payer,
  isCheckResult: (state) => state.permissionStatus.permission.check_result,
  getAssetDashboardDetail: (state) => state.asset_dashboard,
  isFromProfile: (state) => state.isFromProfile,
  regisManual: (state) => state.regisManual,
  detailAssetRegisterNow: (state) => state.detailAssetRegisterNow,
  // isRoleLower: (state) => state.permissionStatus.permission.role.id_role >= 4,
  isRoleLower: (state) =>
    ![
      UserRoles.EXCLUSIVE,
      UserRoles.ACC_MANAGER,
      UserRoles.ACC_OFFICER,
    ].includes(state.permissionStatus.permission.role.id_role),
  getMaintenanceData: (state) => state.all_maintenance_store,

  category: (state) => state.category,
  delcategory: (state) => state.delcategory,
  updatecategory: (state) => state.updatecategory,
  subcategory: (state) => state.subcategory,

  alldepartment: (state) => state.alldepartment,
  department: (state) => state.department,

  allzone: (state) => state.allzone,
  zone: (state) => state.zone,

  getAllColumn: (state) => state.allColumn,
  assets_count: (state) => state.all_asset_count,
  getfilterOption: (state) => state.filterOption,
  deletedAsset: (state) => state.deleted_asset,
  getMaintenancePlanData: (state) => state.maintenance_plan_data,
  getSelectedMaintenanceAsset: (state) => state.selected_maintenance_asset,

  sector: (state) => state.sector,
  sectorSelect: (state) => state.sectorSelect,
  branch: (state) => state.branch,
  vendor: (state) => state.vendor,

  getPageState: (state) => state.pageState,

  getDpNotification: (state) => state.dpRequest,
  haveDpNotification: (state) => {
    if (
      state.dpRequest.activate ||
      state.dpRequest.deactivate ||
      state.dpRequest.write_off ||
      state.dpRequest.cancel_activate ||
      state.dpRequest.cancel_deactivate
    ) {
      return true;
    } else {
      return false;
    }
  },

  noteAccounting: (state) => state.noteAccounting,
  noteAccountingSelect: (state) => state.noteAccountingSelect,

  optionAllIdFixAssets: (state) => state.optionAllIdFixAssets,

  packageLimit: (state) => state.packageLimit,
  maintainAssetList: state => state.maintainAssetList,
};
