import Vue from "vue";
import VueRouter from "vue-router";
import Cookie from "js-cookie";
import axios from "axios";
import { baseUrl } from "../util/backend.js";

import { UserRoles } from "@/constants/UserRoles.js";

import store from "../store/index";
import Login from "../views/Login.vue";
import SelectCompany from "../views/SelectCompany.vue";
import Register from "../views/Register.vue";
import Profile from "../views/Profile.vue";
import ScanQr from "../views/ScanQr.vue";
import RegisterItem from "../views/RegisterItem.vue";
import RegisterNow from "../views/RegisterNow.vue";
import RegisterNative from "../views/RegisterNative.vue";
import DetailOneAsset from "../views/DetailOneAsset_new.vue";
import DigitalSign from "../views/DigitalSign.vue";
import History from "../views/History.vue";
import AddDate from "../views/AddDate.vue";
import EditProfile from "../views/EditProfile.vue";
import CheckItem from "../views/CheckItem.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ChangePasswordFromEmail from "../views/ChangePasswordFromEmail.vue";
import Home from "../views/Home.vue";
import EditDetailAsset from "../views/EditDetailAsset.vue";
import AdminManageUser from "../views/Admin/ManageUser.vue";
import AdminCatAssets from "../views/Admin/CatAssets.vue";
import AdminCatSubAssets from "../views/Admin/CatSubAssets.vue";
import AdminMap from "../views/Admin/Map.vue";
import ManageDepartment from "../views/Admin/ManageDepartment.vue";
import ManageColumnName from "../views/Admin/ManageColumnName.vue";
import ManageZone from "../views/Admin/ManageZone.vue";
import DeletedAssetLog from "../views/Admin/DeletedAssetLog.vue";
import AssetAllEditLog from "../views/Admin/AssetAllEditLog.vue";
import Approve from "../views/Approve.vue";
import ImageAsset from "../views/ImageOneAsset.vue";
import DetailAssetFromDashboard from "../views/AssetFromDashboard/DetailAssetFromDashboard";
import AssetLog from "../views/AssetLog.vue";
import AssetLogDetail from "../views/AssetLogDetail.vue";

import All_asset_JGT from "../views/All_asset_JGT.vue";
import Fixed_Assets from "../views/Fixed_Assets.vue";
import ManageAssetPagination from "../views/Admin/ManageAssetPagination";
import docRegisAssetPagination from "../views/docRegisAssetPagination";
import Dashboard_Pagination from "../views/Dashboard_pagination.vue";
import AssetFromDashboard_Pagination from "../views/AssetFromDashboard/AssetFromDashboard_pagination";
import ReportCountPagination from "../views/ReportCountPagination.vue";
import DepreciationLayout from "../components/layout/Depreciation.vue";
import BorrowReturnLayout from "../components/layout/BorrowReturn.vue";
import MaintenanceLayout from "../components/layout/MaintenanceReport.vue";
import SelectMaintenanceItem from "../views/Maintenance/SelectMaintenanceItem.vue";

Vue.use(VueRouter);

const checkRole = (_, _2, next) => {
  if (!store.getters.isRoleLower) {
    return next();
  } else {
    store.dispatch("logout");
    return next({ name: "Login" });
  }
};

const checkCustom = (to, _2, next) => {
  const custom_id = store.getters.permissionStatus.company.custom_id;
  if (custom_id === to.meta.customId) {
    next();
  } else {
    store.dispatch("logout");
    next({ name: "Login" });
  }
};

const checkCustomWithRole = (to, _2, next) => {
  const custom_id = store.getters.permissionStatus.company.custom_id;
  if (custom_id === to.meta.customId) {
    checkRole(to, _2, next);
  } else {
    store.dispatch("logout");
    next({ name: "Login" });
  }
};

const checkAMAndC = (_, _2, next) => {
  if (
    store.getters.permissionStatus.permission.role.id_role ===
      UserRoles.ACC_MANAGER ||
    store.getters.permissionStatus.permission.role.id_role ===
      UserRoles.EXCLUSIVE
  ) {
    return next();
  } else {
    store.dispatch("logout");
    return next({ name: "Login" });
  }
};

const checkAccountingManager = (_, _2, next) => {
  if (
    store.getters.permissionStatus.permission.role.id_role ===
    UserRoles.ACC_MANAGER
  ) {
    return next();
  } else {
    store.dispatch("logout");
    return next({ name: "Login" });
  }
};

const depreciationGuard = (to, from, next) => {
  if (!store.getters.canUseDepreciation) {
    return next("/");
  }
  return next();
};

const borrowReturnGuard = (to, from, next) => {
  if (!store.getters.canUseBorrowReturn) {
    return next("/");
  }
  const isBorrowReturnAdminOnly = to.meta?.isBorrowReturnAdminOnly || false;

  if (isBorrowReturnAdminOnly && !store.getters.isBorrowReturnAdmin) {
    return next("/");
  }

  return next();
};

const maintenanceGuard = (to, from, next) => {
  store.dispatch(
    "maintenance/setMaintenanceRole",
    store.getters.permissionStatus.permission.role.id_role
  );

  if (!store.getters.canUseMaintenanceReport) {
    return next("/");
  }
  // store.dispatch("maintenance/getMaintenanceRole");
  const maintenancePermission = store.getters["maintenance/maintenanceRole"];
  if (!to.meta.permission.includes(maintenancePermission)) {
    return next("/");
  }

  return next();
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:type",
    name: "LoginType",
    component: Login,
  },
  {
    path: "/close",
    name: "WebDownPage",
    component: () => import("@/views/WebDownPage.vue"),
  },
  // {
  //   path: "/selectCompany/:page",
  //   name: "SelectCompany",
  //   component: SelectCompany,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/selectCompany",
    name: "SelectCompany",
    component: SelectCompany,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/selectCompany/:page",
    name: "SelectCompany",
    component: SelectCompany,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ReportCount/:id_summary",
    name: "ReportCount",
    component: ReportCountPagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ReportCountPagination/:id_summary",
    name: "ReportCount",
    component: ReportCountPagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/docRegisAsset",
    name: "docRegisAsset",
    component: docRegisAssetPagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/docRegisAsset/:summaryId",
    name: "docRegisAssetBySummary",
    component: docRegisAssetPagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/registerNative",
    name: "RegisterNative",
    component: RegisterNative,
  },

  {
    path: "/registerItem/:id",
    name: "RegisterItem",
    component: RegisterItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registerItem/:id/:page",
    name: "RegisterItem",
    component: RegisterItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registerNow/:id",
    name: "RegisterNow",
    component: RegisterNow,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registerNow/:id/:to",
    name: "RegisterNow",
    component: RegisterNow,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/check/:id",
    name: "CheckItem",
    component: CheckItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check/:id/:page",
    name: "CheckItem",
    component: CheckItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sign",
    name: "DigitalSign",
    component: DigitalSign,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/adddate",
    name: "AddDate",
    component: AddDate,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: checkAMAndC,
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/edit_profile",
    name: "EditProfile",
    component: EditProfile,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/changePasswordMail/:id",
    name: "ChangePasswordFromEmail",
    component: ChangePasswordFromEmail,
  },

  {
    path: "/scan",
    name: "ScanQr",
    component: ScanQr,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scan/:page",
    name: "ScanQrPage",
    component: ScanQr,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:id_summary",
    name: "Dashboard",
    component: Dashboard_Pagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:id_summary/:status",
    name: "AssetFromDashboard",
    component: AssetFromDashboard_Pagination,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assetCounting/:id_summary/:id",
    name: "DetailAssetFromDashboard",
    component: DetailAssetFromDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/editDetailAsset/:id",
    name: "EditDetailAsset",
    component: EditDetailAsset,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/detailOneAsset/:id",
    name: "DetailOneAsset",
    component: DetailOneAsset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/detailOneAsset/:id/:id_qrcode",
    name: "DetailOneAssetQR",
    component: DetailOneAsset,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/fixed_assets",
    name: "Fixed_Assets",
    component: Fixed_Assets,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/all_asset",
    name: "All_asset",
    component: All_asset_JGT,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/select_asset_maintenance",
    name: "select_asset_maintenance",
    component: SelectMaintenanceItem,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/all_asset/:id_qrcode",
    name: "All_asset_select",
    component: All_asset_JGT,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/all_image/:id",
    name: "ImageAsset",
    component: ImageAsset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/ManageAsset",
    name: "AdminManageAsset",
    component: ManageAssetPagination,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/ManageUser",
    name: "AdminManageUser",
    component: AdminManageUser,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/CatAssets",
    name: "AdminCatAssets",
    component: AdminCatAssets,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/CatSubAssets/:id_category",
    name: "CatSubAssets",
    component: AdminCatSubAssets,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/Map",
    name: "AdminMap",
    component: AdminMap,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/ManageDepartment",
    name: "ManageDepartment",
    component: ManageDepartment,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/ManageColumnName",
    name: "ManageColumnName",
    component: ManageColumnName,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/ManageZone",
    name: "ManageZone",
    component: ManageZone,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/DeletedAssetLog",
    name: "DeletedAssetLog",
    component: DeletedAssetLog,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/AssetAllEditLog",
    name: "AssetAllEditLog",
    component: AssetAllEditLog,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/approve",
    name: "Approve",
    component: Approve,
  },
  {
    path: "/assetlog/:id_asset",
    name: "AssetLog",
    component: AssetLog,
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assetlogdetail/:id_asset",
    name: "AssetLogDetail",
    component: AssetLogDetail,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/MaintenancePlan",
    name: "MaintenancePlan",
    component: () => import("../views/CustomPage/MaintenancePlan.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/CreateMaintenancePlan",
    name: "CreateMaintenancePlan",
    component: () => import("../views/CustomPage/CreateMaintenancePlan.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/EditMaintenancePlan/:planId",
    name: "EditMaintenancePlan",
    component: () => import("../views/CustomPage/EditMaintenancePlan.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/MaintenancePlanList/:planId",
    name: "MaintenancePlanList",
    component: () => import("../views/CustomPage/MaintenancePlanList.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/MaintenancePlanDetail/:page/:id",
    name: "MaintenancePlanDetail",
    component: () => import("../views/CustomPage/MaintenancePlanDetail.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/MaintenanceHistoryDetail/:assetId",
    name: "MaintenanceHistoryDetail",
    component: () => import("../views/CustomPage/MaintenanceHistoryDetail.vue"),
    beforeEnter: checkCustom,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/admin/ManageBranch",
    name: "ManageBranch",
    component: () => import("../views/CustomPage/ManageBranch.vue"),
    beforeEnter: checkCustomWithRole,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/admin/ManageBranchOnLocation/:sector_id",
    name: "ManageBranchOnLocation",
    component: () => import("../views/CustomPage/ManageBranchOnLocation.vue"),
    beforeEnter: checkCustomWithRole,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/admin/ManageVendor",
    name: "ManageVendor",
    component: () => import("../views/CustomPage/ManageVendor.vue"),
    beforeEnter: checkCustomWithRole,
    meta: {
      requiresAuth: true,
      customId: 2,
    },
  },
  {
    path: "/depreciation",
    component: DepreciationLayout,
    beforeEnter: depreciationGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "asset",
        name: "depreciation-asset",
        component: () => import("../views/Depreciation/Asset.vue"),
      },
      {
        path: "deactivate",
        name: "deactivate-asset",
        component: () => import("../views/Depreciation/DeactivateAsset.vue"),
      },
      {
        path: "activate",
        name: "activate-asset",
        component: () => import("../views/Depreciation/ActivateAsset.vue"),
      },
      {
        path: "writeoff",
        name: "writeoff-asset",
        component: () => import("../views/Depreciation/WriteoffAsset.vue"),
      },
      {
        path: "confirmation",
        name: "confirm-action",
        component: () => import("../views/Depreciation/ConfirmAction.vue"),
        beforeEnter: checkAccountingManager,
      },
      {
        path: "report",
        name: "depreciation-report",
        component: () => import("../views/Depreciation/Report.vue"),
      },
      {
        path: "jv",
        name: "jv-document",
        component: () => import("../views/Depreciation/JVDocument.vue"),
      },
      {
        path: "pausedassets",
        name: "paused-assets",
        component: () => import("../views/Depreciation/PausedAssetList.vue"),
      },
      {
        path: "usingexpiredasset",
        name: "using-expired-asset",
        component: () => import("../views/Depreciation/ExpiredUsingAsset.vue"),
      },
      {
        path: "soldasset",
        name: "sold-asset",
        component: () => import("../views/Depreciation/SoldAsset.vue"),
      },
      {
        path: "accountingnote",
        name: "accounting-note",
        component: () => import("../views/Depreciation/AccountingNote.vue"),
      },
      {
        path: "construction",
        name: "construction-asset",
        component: () => import("../views/Depreciation/ConstructionAsset.vue"),
      },
      {
        path: "constructionhistory",
        name: "construction-history",
        component: () =>
          import("../views/Depreciation/ConstructionHistory.vue"),
      },
      {
        path: "managefinancialnote",
        name: "manage-financial-note",
        component: () =>
          import("../views/Depreciation/ManageFinancialNote.vue"),
      },
      {
        path: "managesubfinancialnote/:note_accounting_id",
        name: "manage-sub-financial-note",
        component: () =>
          import("../views/Depreciation/ManageSubFinancialNote.vue"),
      },
      {
        path: "accountperiod",
        name: "account-period",
        component: () => import("../views/Depreciation/AccountPeriod.vue"),
        beforeEnter: checkAccountingManager,
      },
      {
        path: "landlist",
        name: "land-list",
        component: () => import("../views/Depreciation/LandList.vue"),
      },
      {
        path: "landeditlog",
        name: "land-edit-log",
        component: () => import("../views/Depreciation/LandEditLog.vue"),
      },
      {
        path: "landlogdetail/:id_asset",
        name: "land-log-detail",
        component: () => import("../views/Depreciation/LandLogDetail.vue"),
      },
      {
        path: "history",
        name: "depreciation-status-history",
        component: () => import("../views/Depreciation/History.vue"),
      },
    ],
  },
  {
    path: "/borrowreturn",
    component: BorrowReturnLayout,
    beforeEnter: borrowReturnGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "borrow-dashboard",
        component: () => import("../views/BorrowReturn/Dashboard.vue"),
      },
      {
        path: "borrowlist",
        name: "borrow-list",
        component: () => import("../views/BorrowReturn/BorrowList.vue"),
      },
      {
        path: "addborrow",
        name: "add-borrow",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/AddBorrow.vue"),
      },
      {
        path: "removeborrow",
        name: "remove-borrow",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/RemoveBorrow.vue"),
      },
      {
        path: "borroworder",
        name: "borrow-order",
        component: () => import("../views/BorrowReturn/CreateBorrowOrder.vue"),
      },
      {
        path: "editorder/:borrow_return_asset_id",
        name: "edit-order",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/EditBorrowOrder.vue"),
      },
      {
        path: "editorder/:borrow_return_asset_id/editselect",
        name: "edit-order-select",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/EditSelectBorrow.vue"),
      },
      {
        path: "selectborrow",
        name: "select-borrow",
        component: () => import("../views/BorrowReturn/SelectBorrow.vue"),
      },
      {
        path: "qrscan",
        name: "borrow-qr-scanner",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/QRScanner.vue"),
      },
      {
        path: "manageorder",
        name: "manage-order",
        component: () => import("../views/BorrowReturn/ManageOrder.vue"),
      },
      {
        path: "borrowhistory",
        name: "borrow-history",
        component: () => import("../views/BorrowReturn/BorrowHistory.vue"),
      },
      {
        path: "setting",
        name: "borrow-setting",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () => import("../views/BorrowReturn/Setting.vue"),
      },
      {
        path: "assets/:qrAssetId",
        name: "borrowable-asset",
        meta: {
          isBorrowReturnAdminOnly: true,
        },
        component: () =>
          import("../views/BorrowReturn/BorrowableAssetDetail.vue"),
      },
    ],
  },
  {
    path: "/Maintenance",
    component: MaintenanceLayout,
    beforeEnter: maintenanceGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "MyMaintenanceReportList/:assetId?",
        name: "MyMaintenanceReportList",
        component: () =>
          import("../views/Maintenance/MyMaintenanceReportList.vue"),
        meta: {
          permission: ["user", "admin", "low-technician", "high-technician"],
        },
      },
      {
        path: "MaintenanceDashboard",
        name: "MaintenanceDashboard",
        component: () =>
          import("../views/Maintenance/MaintenanceDashboard.vue"),
        meta: {
          permission: ["admin", "low-technician", "high-technician"],
        },
      },
      {
        path: "MaintenanceTechnician",
        name: "MaintenanceTechnician",
        component: () =>
          import("../views/Maintenance/MaintenanceTechnician.vue"),
        meta: {
          permission: ["user", "admin", "low-technician", "high-technician"],
        },
      },
      {
        path: "MyMaintenanceList",
        name: "MyMaintenanceList",
        component: () =>
          import("../views/Maintenance/MyMaintenanceReportList.vue"),
        meta: {
          permission: ["low-technician", "high-technician"],
        },
      },
      {
        path: "MaintenanceReportAll",
        name: "MaintenanceReportAll",
        component: () =>
          import("../views/Maintenance/MyMaintenanceReportList.vue"),
        meta: {
          permission: ["admin", "high-technician"],
        },
      },
      {
        path: "MaintenanceHistory",
        name: "MaintenanceHistory",
        component: () => import("../views/Maintenance/MaintenanceHistory.vue"),
        meta: {
          permission: ["admin", "high-technician"],
        },
      },
    ],
  },
  {
    path: "/maintenance-scanner",
    name: "MaintenanceScanner",
    component: () => import("../views/Maintenance/QRScanner.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ScanMaintenanceAssetCheck/:qrAssetId",
    name: "ScanMaintenanceAssetCheck",
    component: () =>
      import("../views/Maintenance/ScanMaintenanceAssetCheck.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/EditSubAsset/:assetId",
    name: "EditSubAsset",
    component: () => import("../views/CustomPage/SubAsset/EditSubAsset.vue"),
    beforeEnter: checkCustomWithRole,
    meta: {
      requiresAuth: true,
      customId: 3,
    },
  },
  {
    path: "/SelectAsset/:assetId",
    name: "SelectAsset",
    component: () => import("../views/CustomPage/SubAsset/SelectAsset.vue"),
    beforeEnter: checkCustomWithRole,
    meta: {
      requiresAuth: true,
      customId: 3,
    },
  },
  // -------------- Custom ID 4 --------------
  {
    path: "/admin/AssetModel/:id_subcategory",
    name: "AssetModel",
    component: () => import("../views/CustomPage/AssetModel.vue"),
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
    },
  },
  // -------------- END Custom ID 4 --------------
  // -------------- Custom ID 8 --------------
  {
    path: "/org",
    name: "org",
    component: () => import("../views/CustomPage/Hoshi/CompanyGraph.vue"),
    meta: {
      requiresAuth: true,
      // customId: 8,
    },
  },
  {
    path: "/admin/ManageRoom/:floorId",
    name: "ManageRoom",
    component: () => import("../views/CustomPage/Hoshi/ManageRoom.vue"),
    beforeEnter: checkRole,
    meta: {
      requiresAuth: true,
      // customId: 8,
    },
  },
  {
    path: "/DiagramAsset",
    name: "diagram-assets",
    component: () => import("../views/CustomPage/Hoshi/DiagramAssets.vue"),
    meta: {
      requiresAuth: true,
      // customId: 8,
    },
  },
  // -------------- END Custom ID 8 --------------
  {
    path: "/sponga-redirect",
    name: "sponga-redirect",
    component: () => import("../views/Redirect/Sponga.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // ------------------- for maintenance mode ------------------------
    if (
      (store.getters.isTester && store.getters.isInMaintenanceMode) ||
      !store.getters.isInMaintenanceMode
    ) {
      // ------------------- for maintenance mode ------------------------
      axios.interceptors.response.use(undefined, async function(error) {
        const cookieuser = Cookie.get("cookielogin");
        const originalRequest = error.config;
        if (error.response) {
          if (error.response.status === 401) {
            let json = JSON.parse(cookieuser);
            if (error.response.data.message == "Invalid token") {
              // Cookie.remove("cookielogin");
              // Cookie.remove(selectedCompany);
              Object.keys(Cookie.get()).forEach(function(cookieName) {
                console.log("remove cookie:", cookieName);
                Cookie.remove(cookieName, {
                  path: "/",
                  domain: location.hostname.endsWith(".verscan.com")
                    ? ".verscan.com"
                    : "localhost",
                });
              });
              store.dispatch("logout");
              return next({ name: "Login" });
            }

            if (error.response.data.message == "Permission") {
              alert("PERMISSION");
              // Cookie.remove("cookielogin");
              // Cookie.remove(selectedCompany);
              Object.keys(Cookie.get()).forEach(function(cookieName) {
                console.log("remove cookie:", cookieName);
                Cookie.remove(cookieName, {
                  path: "/",
                  domain: location.hostname.endsWith(".verscan.com")
                    ? ".verscan.com"
                    : "localhost",
                });
              });
              store.dispatch("logout");
              return next({ name: "Login" });
            }
            if (
              error.response.data.message == "jwt expired" &&
              !originalRequest._retry
            ) {
              // ยิง Refresh token เพื่อเอา Access Token ใหม่
              originalRequest._retry = true;
              const response = await axios.post(
                `${baseUrl()}user/refreshtoken`,
                {
                  refresh_token: json.refresh_token,
                  id_user: json.id_user,
                }
              );
              if (response.data.message == "success") {
                json.access_token = response.data.token;
                Cookie.set("cookielogin", json, {
                  expires: 7,
                  domain: location.hostname.endsWith(".verscan.com")
                    ? "verscan.com"
                    : null,
                });
                error.config.headers["Authorization"] =
                  "Bearer " + json.access_token;
                return axios(originalRequest);
              } else {
                // Cookie.remove("cookielogin");
                Object.keys(Cookie.get()).forEach(function(cookieName) {
                  console.log("remove cookie:", cookieName);
                  Cookie.remove(cookieName, {
                    path: "/",
                    domain: location.hostname.endsWith(".verscan.com")
                      ? ".verscan.com"
                      : "localhost",
                  });
                });
                store.dispatch("logout");
                return next({ name: "Login" });
              }
            }
          }
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      });
      let data = Cookie.get("cookielogin");
      if (!store.getters.getUserDetail) {
        store.dispatch("getUser");
      }
      if (data) {
        const selectedCompany = Cookie.get("selectedcompany");
        if (selectedCompany) {
          store.commit("setPermission", JSON.parse(selectedCompany));
          next();
        } else {
          if (to.name === "SelectCompany") {
            next();
          } else {
            next({ name: "SelectCompany" });
          }
        }
      } else {
        next({ name: "Login" });
      }
    } else {
      // ------------------- for maintenance mode ------------------------
      store.dispatch("logout");
      // ------------------- for maintenance mode ------------------------
    }
  } else {
    next();
  }
});

export default router;
