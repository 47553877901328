<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <div class="register">
      <!-- <Background></Background> -->
      <div class="card-div-2">
        <div class="back-icon">
          <img src="../assets/previous.svg" width="30" @click="goBack()" />
        </div>
        <h1 class="header-text">
          <div class="topic">{{ $t("registeritem.as_reg") }}</div>
        </h1>
        <!-- PROFILE -->
        <div class="frame-profile">
          <img
            v-if="data.image_url"
            :src="data.image_url"
            class="banner-profile"
          />
          <img
            v-else
            class="banner"
            src="../assets/image.svg"
            style="cursor: pointer"
            alt=""
            @click="$refs.photo.click()"
          />
          <img
            v-if="assetsPicture !== ''"
            class="banner"
            :src="assetsPicture"
            alt=""
          />
          <div class="upload-icon-plus">
            <label for="fusk">
              <img src="../assets/add.svg" style="cursor: pointer" />
            </label>
            <input
              ref="photo"
              id="fusk"
              type="file"
              accept="image/png, image/jpeg"
              name="photo"
              style="display: none; margin: 0px !important"
              @change="onFileChange($event)"
            />
          </div>
        </div>

        <!-- Form -->
        <div class="card-form-login">
          <div>
            <label class="mt-4"
              >{{ $t("registeritem.qr_id") }}: {{ data.id_qrcode }}</label
            >
          </div>
          <div class="sub-menu">
            <b-button
              @click="toAllAssetForListToFloor"
              variant="primary"
              class="h-100"
              >{{ $t("registeritem.search_button") }}</b-button
            >
          </div>
          <form @submit.prevent="registerItem" v-if="!manual_asset">
            <div class="input-group">
              <label
                >{{ $t("registeritem.as_num") }}:
                <span class="require-red">*</span>
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :options="assetListOptions"
                label="label"
                :reduce="(option) => option.value"
                @input="onselectAssetFixedId"
                v-model="assetFixedId"
              />
            </div>

            <div class="input-group text-start">
              <b-form-checkbox
                id="manual_asset"
                @change="toggleManual($event)"
                v-model="manual_asset"
              >
                <p class="px-2" style="font-size: 16px">
                  {{ $t("registeritem.add_new_asset") }}
                </p>
              </b-form-checkbox>
            </div>

            <div class="input-group">
              <label
                >{{ $t("registeritem.as_name")
                }}<span class="require-red">*</span></label
              >
              <div class="d-flex cus-btn" style="column-gap: 0.5rem;">
                <input
                  type="text"
                  v-model="data.name"
                  class="w-100"
                  required
                  :disabled="!assetFixedId"
                />
                <!-- <button
                  v-if="
                    this.permissionStatus.company.custom_id === 4 &&
                      this.isNativeApp
                  "
                  type="button"
                  @click="OpenScanBarcode"
                  class="scan-btn"
                >
                  <b-icon icon="upc-scan"></b-icon>
                </button> -->
              </div>
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_cat") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_category"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_category: null,
                  },
                  ...(assetOptions.optionCategory
                    ? assetOptions.optionCategory
                    : []),
                ]"
                v-model="data.category"
                :disabled="!assetFixedId"
              />
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_sub_cat") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <!-- <select
                v-model="data.sub_category"
                class="dropdown form-control w-100"
                :disabled="!data.category || !assetFixedId"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="sub in subOptionCategory"
                  :key="sub.id_sub_category"
                  :value="sub.id_sub_category"
                >
                  {{ sub.name }}
                </option>
              </select> -->
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_sub_category"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_sub_category: null,
                  },
                  ...subOptionCategory,
                ]"
                v-model="data.sub_category"
                :disabled="!data.category || !assetFixedId"
              />
            </div>
            <div class="input-group" v-if="canUseCustom4">
              <label
                >{{ $t("model.model") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_sub_category2"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_sub_category2: null,
                  },
                  ...subOptionCategory2,
                ]"
                v-model="data.id_sub_category2"
                :disabled="!data.sub_category || !assetFixedId"
              />
            </div>

            <div class="input-group" v-if="data.category === 'เพิ่มหมวดหมู่'">
              <label></label>
              <input type="text" required v-model="newCategory" />
            </div>
            <div class="input-group">
              <label>{{ $t("asset.as_dt") }}</label>
              <textarea
                v-model="data.description"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <div class="input-group">
              <label>{{ $t("home.note") }}</label>
              <textarea
                v-model="data.note"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <customInputAssetInfo
              v-if="isCustom"
              :customid="permissionStatus.company.custom_id"
              :disable="!assetFixedId"
              :options="assetOptions"
              :data="data"
              @set-custom-data="setCustomData"
            />

            <div class="input-group">
              <label
                >{{ $t("asset.as_loc") }}
                <!-- <span class="require-red">*</span> -->
              </label>

              <label>
                {{ $t("registeritem.build")
                }}<span class="require-red" v-if="canUseCustom5">*</span>
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_building"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_building: null,
                  },
                  ...(assetOptions.optionBuilding
                    ? assetOptions.optionBuilding
                    : []),
                ]"
                v-model="data.location_building"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!data.location_building && canUseCustom5"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>

            <div class="input-group">
              <label>{{ $t("registeritem.fl") }}</label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_floor"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_floor: null,
                  },
                  ...floorOptions,
                ]"
                v-model="data.location_floor"
                :disabled="!assetFixedId"
              />
            </div>

            <div class="input-group">
              <label>{{ $t("registeritem.dep") }}</label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.label"
                :reduce="(option) => option.value"
                :options="optionDepartment"
                v-model="data.location_department"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>

            <div class="input-group">
              <label>
                {{ $t("registeritem.zone")
                }}<span class="require-red" v-if="canUseCustom5">*</span>
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_zone"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_zone: null,
                  },
                  ...(assetOptions.optionZone ? assetOptions.optionZone : []),
                ]"
                v-model="data.location_zone"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!data.location_zone && canUseCustom5"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>

            <div class="input-group py-3">
              <image-map :disabled="!isMap">
                <image-map-display v-if="isMap" class="mt-3" />
              </image-map>
            </div>

            <div class="modal fade">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <label class="font-18"
                    >{{ $t("registeritem.specify_loc") }}
                  </label>
                  <button
                    type="button"
                    class="btn btn-indoor-modal"
                    data-dismiss="modal"
                  >
                    {{ $t("registeritem.conf") }}
                  </button>
                </div>
              </div>
            </div>

            <div v-if="!isRoleLower">
              <template v-if="canUseDepreciation">
                <ExpandHeightHeader
                  :expand="expandDpInfo"
                  :label="$t('registeritem.add_dp_info')"
                  @toggle="expandDpInfo = !expandDpInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandDpInfo">
                    <div class="input-group">
                      <label>
                        {{ $t("asset.acc_note") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="(option) => option.name"
                        :reduce="(option) => option"
                        :options="[
                          {
                            name: $t('home.not_specified'),
                            id_location_zone: null,
                          },
                          ...(assetOptions.optionsNoteAccounting
                            ? assetOptions.optionsNoteAccounting
                            : []),
                        ]"
                        v-model="data.note_accounting"
                        :disabled="!assetFixedId"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("registeritem.note_accounting_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_asset && option.name_asset
                              ? `${option.code_asset} - ${option.name_asset}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("depreciation.code_acc") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc && option.name_acc
                              ? `${option.code_acc} - ${option.name_acc}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("asset.accumulate_dep_acc_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc_dp && option.name_acc_dp
                              ? `${option.code_acc_dp} - ${option.name_acc_dp}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_date") }}</label>
                      <!-- <p>{{data.purchase_date}}</p>  -->
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.purchase_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.start_date") }}</label>

                      <date-picker
                        class="inline-block h-full"
                        v-model="data.start_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_price") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.purchase_price"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.useful_life") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="data.useful_life"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.scrap_value") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.scrap_value"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.dep_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.depreciation_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group" v-if="canUseCustom9">
                      <label>{{ $t("asset.sum_depreciation_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.sum_depreciation_date"
                        locale="en"
                        :max-date="new Date()"
                        :disabled-dates="{
                          days: DISABLED_DATES,
                        }"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_dep") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.prior_depreciation"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_nb") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="data.prior_net_book"
                        :disabled="!assetFixedId"
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </template>

              <!-- ค่าเสื่อมราคาสะสม & มูลค่าคงเหลือ -->
              <!-- <div class="input-group">
                <label>{{ $t("asset.acc_depreciation") }}</label>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="data.acc_depreciation"
                  :disabled="!assetFixedId"
                />
              </div>
              <div class="input-group">
                <label>{{ $t("asset.net_book") }}</label>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="data.net_book"
                  :disabled="!assetFixedId"
                />
              </div> -->
              <div v-if="otherColumnAsset.length >= 0">
                <ExpandHeightHeader
                  :expand="expandAdditionalInfo"
                  :label="$t('registeritem.additional_info')"
                  @toggle="expandAdditionalInfo = !expandAdditionalInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandAdditionalInfo">
                    <div
                      class="input-group"
                      v-for="(item, index) in otherColumnAsset"
                      :key="index"
                    >
                      <label>
                        {{ item.name
                        }}<span
                          class="require-red"
                          v-if="
                            (canUseCustom5 && item.column_no == 2) ||
                              (canUseCustom7 && item.column_no == 1)
                          "
                          >*</span
                        >
                      </label>
                      <input
                        type="text"
                        v-model="item.value"
                        :disabled="!assetFixedId"
                        :required="
                          (canUseCustom5 && item.column_no == 2) ||
                            (canUseCustom7 && item.column_no == 1)
                        "
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </div>
            </div>

            <button class="btn btn-login mt-3" type="submit">
              {{ $t("registeritem.regist") }}
            </button>
          </form>
          <form @submit.prevent="registerItemManual" v-else>
            <div class="input-group">
              <label
                >{{ $t("registeritem.as_num") }}:
                <span class="require-red">*</span>
              </label>
              <div class="d-flex" style="column-gap: 1rem;">
                <input
                  type="text"
                  style="width: 100%;"
                  v-model="assetFixedId"
                  required
                />
              </div>
            </div>

            <div class="input-group text-start">
              <b-form-checkbox
                id="manual_asset"
                @change="toggleManual($event)"
                v-model="manual_asset"
              >
                <p class="px-2" style="font-size: 16px">
                  {{ $t("registeritem.add_new_asset") }}
                </p>
              </b-form-checkbox>
            </div>
            <div v-if="isCustom">
              <customFixedAssetNo
                :assetFixedId="assetFixedId"
                @set-asset-fixed-id="setAssetFixedID"
              ></customFixedAssetNo>
            </div>
            <div class="input-group">
              <label
                >{{ $t("registeritem.as_name")
                }}<span class="require-red">*</span></label
              >
              <div class="d-flex cus-btn" style="column-gap: 0.5rem;">
                <input
                  type="text"
                  class="w-100"
                  v-model="data.name"
                  required
                  :disabled="!assetFixedId"
                />
                <button
                  v-if="
                    this.permissionStatus.company.custom_id === 4 &&
                      this.isNativeApp
                  "
                  type="button"
                  @click="OpenScanBarcode"
                  class="scan-btn"
                >
                  <b-icon icon="upc-scan"></b-icon>
                </button>
              </div>
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_cat") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <!-- <select
                v-model="data.category"
                class="dropdown form-control w-100"
                :disabled="!assetFixedId"
              >
                <option :value="null">{{ $t("home.not_specified") }}</option>
                <option
                  v-for="cate in assetOptions.optionCategory"
                  :key="cate.id_category"
                  :value="cate.id_category"
                >
                  {{ cate.name }}
                </option>
              </select> -->
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_category"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_category: null,
                  },
                  ...(assetOptions.optionCategory
                    ? assetOptions.optionCategory
                    : []),
                ]"
                v-model="data.category"
                :disabled="!assetFixedId"
              />
            </div>
            <div class="input-group" v-if="data.category === 'เพิ่มหมวดหมู่'">
              <label></label>
              <input
                type="text"
                required
                placeholder="กรุณาระบุหมวดหมู่"
                v-model="newCategory"
              />
            </div>

            <div class="input-group">
              <label
                >{{ $t("asset.as_sub_cat") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_sub_category"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_sub_category: null,
                  },
                  ...subOptionCategory,
                ]"
                v-model="data.sub_category"
                :disabled="!data.category || !assetFixedId"
              />
            </div>

            <div class="input-group" v-if="canUseCustom4">
              <label
                >{{ $t("model.model") }}
                <!-- <span class="require-red">*</span> -->
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_sub_category2"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_sub_category2: null,
                  },
                  ...subOptionCategory2,
                ]"
                v-model="data.id_sub_category2"
                :disabled="!data.sub_category || !assetFixedId"
              />
            </div>

            <div class="input-group">
              <label>{{ $t("asset.as_dt") }}</label>
              <textarea
                v-model="data.description"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <div class="input-group">
              <label>{{ $t("home.note") }}</label>
              <textarea
                v-model="data.note"
                :disabled="!assetFixedId"
              ></textarea>
            </div>

            <customInputAssetInfo
              v-if="isCustom"
              :customid="permissionStatus.company.custom_id"
              :disable="!assetFixedId"
              :options="assetOptions"
              :data="data"
              @set-custom-data="setCustomData"
            />

            <div class="input-group">
              <label
                >{{ $t("asset.as_loc") }}
                <!-- <span class="require-red">*</span> -->
              </label>

              <label>
                {{ $t("registeritem.build")
                }}<span class="require-red" v-if="canUseCustom5">*</span>
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_building"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_building: null,
                  },
                  ...(assetOptions.optionBuilding
                    ? assetOptions.optionBuilding
                    : []),
                ]"
                v-model="data.location_building"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!data.location_building && canUseCustom5"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>

            <div class="input-group">
              <label>{{ $t("registeritem.fl") }}</label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_floor"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_floor: null,
                  },
                  ...floorOptions,
                ]"
                v-model="data.location_floor"
                :disabled="!assetFixedId"
              />
            </div>

            <div class="input-group">
              <label>{{ $t("registeritem.dep") }}</label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.label"
                :reduce="(option) => option.value"
                :options="optionDepartment"
                v-model="data.location_department"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>

            <div class="input-group">
              <label>
                {{ $t("registeritem.zone")
                }}<span class="require-red" v-if="canUseCustom5">*</span>
              </label>
              <v-select
                :clearable="false"
                class="search-select"
                :getOptionLabel="(option) => option.name"
                :reduce="(option) => option.id_location_zone"
                :options="[
                  {
                    name: $t('home.not_specified'),
                    id_location_zone: null,
                  },
                  ...(assetOptions.optionZone ? assetOptions.optionZone : []),
                ]"
                v-model="data.location_zone"
                :disabled="!assetFixedId"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!data.location_zone && canUseCustom5"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>

            <div class="input-group py-3">
              <image-map :disabled="!isMap">
                <image-map-display v-if="isMap" class="mt-3" />
              </image-map>
            </div>

            <div class="modal fade">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <label class="font-18">{{
                    $t("registeritem.specify_loc")
                  }}</label>
                  <button
                    type="button"
                    class="btn btn-indoor-modal"
                    data-dismiss="modal"
                  >
                    {{ $t("registeritem.conf") }}
                  </button>
                </div>
              </div>
            </div>

            <div v-if="!isRoleLower">
              <template v-if="canUseDepreciation">
                <ExpandHeightHeader
                  :expand="expandDpInfo"
                  :label="$t('registeritem.add_dp_info')"
                  @toggle="expandDpInfo = !expandDpInfo"
                />
                <ExpandHeight>
                  <div class="py-3" v-if="expandDpInfo">
                    <div class="input-group">
                      <label>
                        {{ $t("asset.acc_note") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="(option) => option.name"
                        :reduce="(option) => option"
                        :options="[
                          {
                            name: $t('home.not_specified'),
                            id_location_zone: null,
                          },
                          ...(assetOptions.optionsNoteAccounting
                            ? assetOptions.optionsNoteAccounting
                            : []),
                        ]"
                        v-model="data.note_accounting"
                        :disabled="!assetFixedId"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("registeritem.note_accounting_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_asset && option.name_asset
                              ? `${option.code_asset} - ${option.name_asset}`
                              : `(${option.sub_note_accounting_id}) -`
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!assetFixedId"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("depreciation.code_acc") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc && option.name_acc
                              ? `${option.code_acc} - ${option.name_acc}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>
                        {{ $t("asset.accumulate_dep_acc_code") }}
                      </label>
                      <v-select
                        :clearable="false"
                        class="search-select"
                        :getOptionLabel="
                          (option) =>
                            option.code_acc_dp && option.name_acc_dp
                              ? `${option.code_acc_dp} - ${option.name_acc_dp}`
                              : '-'
                        "
                        :reduce="(option) => option"
                        :options="getSubNoteAccountingOptions"
                        v-model="data.sub_note_accounting"
                        :disabled="!data.note_accounting"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_date") }}</label>
                      <!-- <p>{{data.purchase_date}}</p>  -->
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.purchase_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.start_date") }}</label>

                      <date-picker
                        class="inline-block h-full"
                        v-model="data.start_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.purchase_price") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.purchase_price"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.useful_life") }}</label>

                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="data.useful_life"
                        :disabled="!assetFixedId"
                      />
                    </div>
                    <div class="input-group">
                      <label>{{ $t("asset.scrap_value") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.scrap_value"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.dep_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.depreciation_date"
                        locale="en"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group" v-if="canUseCustom9">
                      <label>{{ $t("asset.sum_depreciation_date") }}</label>
                      <date-picker
                        class="inline-block h-full"
                        v-model="data.sum_depreciation_date"
                        locale="en"
                        :max-date="new Date()"
                        :disabled-dates="{
                          days: DISABLED_DATES,
                        }"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <b-row>
                            <b-col cols="3" class="flex items-center m-0 pe-0">
                              <b-button
                                variant="outline-primary"
                                class="text-white"
                                style="width: 100%; border-radius: 2px; height: 100%; min-width: 0px;"
                                type="button"
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              >
                                <b-icon icon="calendar"></b-icon>
                              </b-button>
                            </b-col>
                            <b-col
                              cols="9"
                              class="flex items-center h-100 ps-0"
                            >
                              <input
                                :value="inputValue | formatDate"
                                class="form-control px-20"
                                readonly
                                @click="togglePopover()"
                                :disabled="!assetFixedId"
                              />
                            </b-col>
                          </b-row>
                        </template>
                      </date-picker>
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_dep") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.prior_depreciation"
                        :disabled="!assetFixedId"
                      />
                    </div>

                    <div class="input-group">
                      <label>{{ $t("asset.prior_nb") }}</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        v-model.number="data.prior_net_book"
                        :disabled="!assetFixedId"
                      />
                    </div>
                  </div>
                </ExpandHeight>
              </template>
            </div>

            <div v-if="otherColumnAsset.length >= 0">
              <ExpandHeightHeader
                :expand="expandAdditionalInfo"
                :label="$t('registeritem.additional_info')"
                @toggle="expandAdditionalInfo = !expandAdditionalInfo"
              />
              <ExpandHeight>
                <div class="py-3" v-if="expandAdditionalInfo">
                  <div
                    class="input-group"
                    v-for="(item, index) in otherColumnAsset"
                    :key="index"
                  >
                    <label>
                      {{ item.name
                      }}<span
                        class="require-red"
                        v-if="
                          (canUseCustom5 && item.column_no == 2) ||
                            (canUseCustom7 && item.column_no == 1)
                        "
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="item.value"
                      :disabled="!assetFixedId"
                      :required="
                        (canUseCustom5 && item.column_no == 2) ||
                          (canUseCustom7 && item.column_no == 1)
                      "
                    />
                  </div>
                </div>
              </ExpandHeight>
            </div>

            <button class="btn btn-login" type="submit">
              {{ $t("registeritem.regist") }}
            </button>
          </form>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Swal from "sweetalert2";
import ImageMap from "@/components/ImageMap.vue";
import ImageMapDisplay from "@/components/ImageMapDisplay.vue";
// import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import { mapGetters, mapActions } from "vuex";

// import Background from "@/components/Background.vue";
import Footer from "@/components/Footer.vue";
import Compressor from "compressorjs";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import axios from "axios";
import baseUrl from "../util/backend";
import { authHeader } from "../store/actions";
import moment from "moment";
import ExpandHeightHeader from "../components/ExpandHeightHeader.vue";
import ExpandHeight from "../components/Animation/expandHeight.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
//  ?? lib auto complete https://www.npmjs.com/package/@trevoreyre/autocomplete-vue#getresultvalue
import customFixedAssetNo from "@/components/CustomComponent/customFixedAssetNo.vue";
import customInputAssetInfo from "../components/CustomComponent/customInputAssetInfo.vue";
export default {
  data() {
    return {
      DISABLED_DATES: [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      id_company: null,
      assetOptions: {},
      assetList: [],
      otherColumn: [],
      otherColumnAsset: [],
      isMap: false,
      expandDpInfo: false,
      expandAdditionalInfo: false,

      isAllowToRegis: false,
      id_asset: "",
      isLoading: false,
      showSucces: true,
      onCancel: false,
      assetsPicture: "",
      url: "",
      manual_asset: false,
      pictureFile: null,
      data: {
        name: null,
        category: null,
        sub_category: null,
        id_sub_category2: null,
        description: "",
        location_building: null,
        location_floor: null,
        location_department: null,
        location_zone: null,
        location_mapX: null,
        location_mapY: null,
        id_fixed_asset: "",
        registrant: "",
        id_qrcode: "",
        note: "",
        start_date: null,
        purchase_date: null,
        purchase_price: null,
        useful_life: null,
        scrap_value: null,
        depreciation_date: null,
        sum_depreciation_date: null,
        prior_depreciation: null,
        prior_net_book: null,
        acc_depreciation: null,
        net_book: null,
        sector: null,
        branch: null,
        custom_start_time: null,
        custom_end_time: null,
        vendor: null,
        asset_details: [],
        note_accounting: null,
        sub_note_accounting: null,
      },
      new_building: "",
      new_floor: "",
      map: "",
      user: "",
      regisid: "",
      assetFixedId: null,
      newCategory: "",
      statusData: [
        { name: "ทั้งหมด", value: "ทั้งหมด" },
        { name: "ใช้งานอยู่", value: "ใช้งานได้" },
        { name: "ไม่ได้ใช้งาน", value: "ไม่ได้ใช้งาน" },
        { name: "รอการประเมิน", value: "รอการประเมิน" },
        { name: "ซ่อมได้", value: "ซ่อมได้" },
        { name: "ซ่อมไม่ได้", value: "ซ่อมไม่ได้" },
        { name: "แจ้งสูญหาย", value: "report" },
        { name: "สูญหาย", value: "lost" },
        // { name: "ยังไม่ได้ลงทะเบียน", value: },
      ],
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  components: {
    autocomplete,
    Footer,
    Loading,
    ImageMap,
    ImageMapDisplay,
    DatePicker,
    customFixedAssetNo,
    customInputAssetInfo,
    ExpandHeight,
    ExpandHeightHeader,
  },

  watch: {
    "data.category"(_, oldItem) {
      if (oldItem) {
        this.data.sub_category = null;
      }
    },
    "data.sub_category"(_, oldItem) {
      if (oldItem) {
        this.data.id_sub_category2 = null;
      }
    },

    "data.location_building"(_, oldItem) {
      if (oldItem) {
        this.data.location_floor = null;
      }
    },

    "data.location_floor"() {
      this.checkMap();
      this.$store.dispatch("setMapPosition", { x: null, y: null });
    },
    "data.note_accounting"(_, oldItem) {
      if (oldItem) {
        this.data.sub_note_accounting = null;
      }
    },
  },

  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getMapPosition: "getMapPosition",
      getAllMap: "getAllMap",
      getRegisAsset: "getRegisAsset",
      getAllAssets: "getAllAssetsStore",
      regisManual: "regisManual",
      getDetailAsset: "getDetailAsset",
      getAssetSelectedRegister: "getAssetSelectedRegister",
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom4: "canUseCustom4",
      canUseCustom5: "canUseCustom5",
      canUseCustom7: "canUseCustom7",
      canUseCustom9: "canUseCustom9",
    }),
    getSubNoteAccountingOptions() {
      return this.data?.note_accounting?.sub_note_accountings || [];
    },
    isCustom() {
      return (
        this.permissionStatus.company.custom_id > 0 &&
        this.permissionStatus.company.custom_id != null
      );
    },
    checkBuilding() {
      if (!this.assetFixedId) {
        return true;
      } else if (
        !this.builddingHeader?.find(
          (each) => each == this.data.location_building
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    assetListOptions() {
      return this.assetList.map((asset) => {
        return {
          label: asset.id_fixed_asset,
          value: asset.id_asset,
        };
      });
    },
    subOptionCategory() {
      const subOptions = this.assetOptions.optionSubCategory?.filter(
        (opt) => opt.id_category === this.data.category
      );
      return subOptions || [];
    },
    subOptionCategory2() {
      const subOptions = this.assetOptions.optionSubCategory2?.filter(
        (opt) => opt.id_sub_category === this.data.sub_category
      );
      return subOptions || [];
    },
    optionDepartment() {
      let department = this.assetOptions?.optionDepartment?.map((item) => {
        return {
          label: `${item.code} - ${item.name}`,
          value: item.id_location_department,
        };
      });
      if (department) {
        department.unshift({
          label: this.$t("home.not_specified"),
          value: null,
        });
      } else {
        department = [
          {
            label: this.$t("home.not_specified"),
            value: null,
          },
        ];
      }
      return department;
    },
    floorOptions() {
      // return [];
      const floorOptions = this.assetOptions.optionFloor?.filter(
        (opt) => opt.id_location_building === this.data.location_building
      );
      return floorOptions || [];
    },
  },

  methods: {
    getSubNoteAccountingOptionsLabel(option) {
      return option?.name;
    },

    toggleManual(e) {
      this.assetFixedId = null;
      this.id_asset = null;
      this.data.name = null;
      this.data.category = null;
      this.data.sub_category = null;
      this.data.id_sub_category2 = null;
      this.data.description = null;
      this.data.id_fixed_asset = null;
      this.data.location_building = null;
      this.data.location_department = null;
      this.data.location_floor = null;
      this.data.location_zone = null;
      this.data.note = null;
      this.data.registrant = null;
      this.data.start_date = null;
      this.data.purchase_date = null;
      this.data.purchase_price = null;
      this.data.useful_life = null;
      this.data.acc_depreciation = null;
      this.data.net_book = null;
      this.data.sector = null;
      this.data.branch = null;
      this.data.custom_start_time = null;
      this.data.custom_end_time = null;
      this.data.vendor = null;
      this.data.asset_details = [];
      this.data.scrap_value = null;
      this.data.depreciation_date = null;
      this.data.prior_depreciation = null;
      this.data.prior_net_book = null;
      this.data.note_accounting = null;
      this.data.sub_note_accounting = null;
      this.mapOtherColumn();
      this.manual_asset = e;

      this.isMap = false;
    },
    onselectAssetFixedId() {
      if (!this.manual_asset) {
        this.mapOtherColumn();
        if (!this.assetFixedId) {
          this.id_asset = null;
          this.data.name = null;
          this.data.category = null;
          this.data.sub_category = null;
          this.data.id_sub_category2 = null;
          this.data.description = null;
          this.data.id_fixed_asset = null;
          this.data.location_building = null;
          this.data.location_department = null;
          this.data.location_floor = null;
          this.data.location_zone = null;
          this.data.note = null;
          this.data.registrant = null;
          this.data.start_date = new Date();
          this.data.purchase_date = new Date();
          this.data.purchase_price = null;
          this.data.useful_life = null;
          this.data.acc_depreciation = null;
          this.data.net_book = null;
          this.data.sector = null;
          this.data.branch = null;
          this.data.custom_start_time = null;
          this.data.custom_end_time = null;
          this.data.vendor = null;
          this.data.asset_details = [];
        } else {
          this.onSubmit(this.assetFixedId);
        }
      }
    },

    setAssetFixedID(data) {
      this.assetFixedId = data.id;
    },
    setCustomData(e) {
      this.data = { ...this.data, ...e };
    },
    setDataFromSearchAsset() {
      const assetId = this.getAssetSelectedRegister;
      this.assetFixedId = assetId;
      this.onSubmit(assetId);
      this.$store.dispatch("setAssetSelectedRegister", null);
      this.mapOtherColumn();
    },
    ...mapActions(["regisAsset", "sendAllMap"]),

    toAllAssetForListToFloor() {
      this.$router.push(
        `/all_asset/${encodeURIComponent(this.data.id_qrcode)}`
      );
    },

    checkMap() {
      const floor = this.assetOptions.optionFloor?.find(
        (opt) => opt.id_location_floor === this.data.location_floor
      );
      if (floor) {
        this.isMap = true;
        this.$store.dispatch("setMapUrl", floor.image_url || "");
      } else {
        this.isMap = false;
      }
    },
    goBack() {
      // if(this.$route.params.id_qrcode)
      this.$router.push("/scan");
    },
    search(input) {
      let search_aseet = this.getAllAssets;
      if (input.length < 1) {
        return [];
      }
      this.data.id_fixed_asset = input;
      const autocomplete = search_aseet.filter((asset) => {
        if (asset.qrcode == null) {
          return asset.id_fixed_asset
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return false;
        }
      });
      return autocomplete;
    },

    getResultValue(result) {
      return result.id_fixed_asset;
    },
    async onSubmit(result) {
      this.isLoading = true;
      const res = await axios.get(
        `${baseUrl()}counting/asset/${result}`,
        authHeader()
      );
      const assetData = res.data.data.asset;

      this.id_asset = assetData.id_asset;
      this.data.name = assetData.name;
      this.data.category = assetData.id_category;
      this.data.sub_category = assetData.id_sub_category;
      this.data.id_sub_category2 = assetData.id_sub_category2;
      this.data.description = assetData.description;
      this.data.id_fixed_asset = assetData.id_fixed_asset;
      this.data.location_building = assetData.id_location_building;
      this.data.location_department = assetData.id_location_department;
      this.data.location_floor = assetData.id_location_floor;
      this.data.location_zone = assetData.id_location_zone;
      this.data.note = assetData.note;
      this.data.registrant = this.user.name;
      this.data.start_date = assetData.start_date;
      this.data.purchase_date = assetData.purchase_date;
      this.data.purchase_price = assetData.purchase_price;
      this.data.useful_life = assetData.useful_life;
      this.data.acc_depreciation = assetData.acc_depreciation;
      this.data.net_book = assetData.net_book;
      this.data.note_accounting = assetData.note_accounting;
      this.data.sub_note_accounting = assetData.sub_note_accounting;
      this.data.scrap_value = assetData.scrap_value;
      this.data.depreciation_date = assetData.depreciation_date;
      this.data.prior_depreciation = assetData.prior_depreciation;
      this.data.prior_net_book = assetData.prior_net_book;
      this.data.asset_details = assetData.asset_details;
      this.data.sector = assetData.sector_id;
      this.data.branch = assetData.branch_id;
      this.data.vendor = assetData.vendor_id;
      this.data.custom_start_time = assetData.custom_start_time;
      this.data.custom_end_time = assetData.custom_end_time;
      this.data.intendant = assetData.intendant;
      this.mapOtherColumn();
      this.$store.dispatch("setMapPosition", {
        x: assetData.location_mapX,
        y: assetData.location_mapY,
      });
      if (assetData.id_location_floor) {
        this.$store.dispatch("setMapUrl", assetData.location_floors.image_url);
        this.isMap = true;
      } else {
        this.isMap = false;
      }
      this.isLoading = false;
    },
    selectRegion(e) {
      console.log(e);
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },

    async upload() {
      this.isLoading = true;
      const fileforupload = await this.compressImage(this.pictureFile);
      const file = new File([fileforupload], this.pictureFile.name, {
        type: "image/jpeg",
      });

      const fd = new FormData();
      const formdata = new FormData();
      formdata.append("folder_id", 2);
      formdata.append("file", file);
      const res = await this.$store.dispatch("uploadImage", formdata);

      this.data.image_url = res.data.data.uploadFileAtLevel.url;

      // this.url_new = res.data.data.uploadFileAtLevel.url;
      // fd.append("image_url", this.url_new);
      // axios
      //   .post("https://api.verscan.com/file/api/file/create", formdata, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     console.log("res.data.data;", res.data.data);
      //     this.url_new = res.data.data.uploadFileAtLevel.url;
      //     this.data.image_url = res.data.data.uploadFileAtLevel.url;
      //     fd.append("image_url", this.url_new);
      //     //this.img1 = res.data.data; // แก้ตรงนี้
      //   });

      // fd.append("id_company", id_company);
      // fd.append("id_asset", id_asset);
      console.log(`fileforupload`, fileforupload);

      this.data.data_form = fd;
      this.isLoading = false;
    },
    momentDay(date) {
      if (date) {
        return moment(date).isValid()
          ? moment(date)
              .startOf("d")
              .toISOString()
          : null;
      } else {
        return null;
      }
    },
    async onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      this.pictureFile = file;
      if (!(await this.$store.dispatch("checkFileType", file))) {
        e.target.value = null;
        return Swal.fire(this.$t("home.up_img"));
      }
      await this.upload();
      e.target = null;
      e.preventDefault();
    },
    async validateCustomInput(type) {
      if (!this.canUseCustom7) return true;
      const other3 = this.otherColumnAsset.find(
        (detail) => detail.column_no == 3
      );
      const errorDetail = [];
      if (type === "manual" && !this.assetFixedId?.includes(" - ")) {
        errorDetail.push(this.$t("registeritem.as_num"));
      }
      if (!other3?.value?.includes(" - ")) {
        errorDetail.push(other3.name);
      }
      if (!this.data.description?.includes(" - ")) {
        errorDetail.push(this.$t("asset.as_dt"));
      }
      if (errorDetail.length > 0) {
        const errorText = errorDetail.join(", ");
        const result = await Swal.fire({
          icon: "error",
          text: `${errorText} ${this.$t("dialog.incorrect_confirm")}`,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.conf"),
          cancelButtonText: this.$t("btn.canc"),
          confirmButtonColor: "#007AFE",
          cancelButtonColor: "#C4C4C4",
          reverseButtons: true,
        });
        return result.isConfirmed;
      } else {
        return true;
      }
    },
    async registerItem() {
      if (this.data.image_url) {
        const check = await this.validateCustomInput();
        if (!check) return;
        this.isLoading = true;
        let position = await this.getMapPosition;
        this.data.registrant = this.user.name;
        console.log(this.data.purchase_date);
        this.data.purchase_date = this.momentDay(this.data.purchase_date);
        this.data.start_date = this.momentDay(this.data.start_date);
        this.data.location_mapX = position.x;
        this.data.location_mapY = position.y;
        if (this.data.category == "เพิ่มหมวดหมู่") {
          this.data.category = this.newCategory;
        }
        if (!this.data.acc_depreciation) this.data.acc_depreciation = null;
        if (!this.data.purchase_price) this.data.purchase_price = null;
        if (!this.data.useful_life) this.data.useful_life = null;
        if (!this.data.net_book) this.data.net_book = null;
        if (!this.data.scrap_value) this.data.scrap_value = null;
        if (!this.data.prior_depreciation) this.data.prior_depreciation = null;
        if (!this.data.prior_net_book) this.data.prior_net_book = null;
        this.data.asset_details = this.otherColumnAsset;

        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        try {
          await this.$store.dispatch("regisAsset", {
            ...this.data,
            id_company: id_company,
            id_asset: this.id_asset,
          });
        } catch (err) {
          if (err === "Reach package limit") {
            Swal.fire({
              icon: "error",
              text: this.$t("dialog.register_limit"),
            });
          } else {
            alert(err);
          }
        }
        this.isLoading = false;
      } else {
        alert(this.$t("dialog.upload_asset_img"));
      }
    },
    async registerItemManual() {
      if (this.data.image_url) {
        const check = await this.validateCustomInput("manual");
        if (!check) return;
        try {
          this.isLoading = true;
          let position = await this.getMapPosition;
          this.data.location_mapX = position.x;
          this.data.location_mapY = position.y;
          // const id_company = this.$store.state.user.id_company;
          const id_company = await this.$store.dispatch("getCompanyIdCookie");
          this.data.id_fixed_asset = this.assetFixedId;
          let data_send = { ...this.data };
          if (this.data.location_building === "อื่นๆ") {
            // this.data.location_building = "อื่นๆ"
            data_send.location_building = this.new_building;
          }
          if (this.data.location_floor === "อื่นๆ") {
            // this.data.location_floor = "อื่นๆ"
            data_send.location_floor = this.new_floor;
          }
          if (this.data.category == "เพิ่มหมวดหมู่") {
            data_send.category = this.newCategory;
          }
          data_send.asset_details = this.otherColumnAsset;
          if (!data_send.purchase_price) data_send.purchase_price = null;
          if (!data_send.useful_life) data_send.useful_life = null;
          if (!data_send.acc_depreciation) data_send.acc_depreciation = null;
          if (!data_send.net_book) data_send.net_book = null;
          if (!data_send.scrap_value) data_send.scrap_value = null;
          if (!data_send.prior_depreciation)
            data_send.prior_depreciation = null;
          if (!data_send.prior_net_book) data_send.prior_net_book = null;

          await this.$store.dispatch("regisAssetManual", {
            ...data_send,
            id_company: id_company,
            id_asset: this.id_asset,
          });
        } catch (err) {
          if (err === "id_fixed_asset is already exist") {
            Swal.fire(this.$t("dialog.as_id_duplicate"));
          } else if (err === "Reach package limit") {
            Swal.fire({
              icon: "error",
              text: this.$t("dialog.register_limit"),
            });
          } else {
            alert(err);
          }
        }
        this.isLoading = false;
      } else {
        alert(this.$t("dialog.upload_asset_img"));
      }
    },
    hide() {
      setTimeout(() => {
        this.showSucces = false;
      }, 3000);
    },
    async loadAssetOptions() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/get/option/${this.id_company}`,
          authHeader()
        );
        this.assetOptions = res.data.data;
        console.log(this.assetOptions);
      } catch (err) {
        console.log(err);
      }
    },
    async loadAssetList() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getassetid/${this.id_company}`,
          authHeader()
        );
        this.assetList = res.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async loadOtherColumn() {
      try {
        const res = await axios.get(
          `${baseUrl()}admin/other_column?id_company=${this.id_company}`,
          authHeader()
        );
        this.otherColumn = res.data.data;
        this.mapOtherColumn();
      } catch (err) {
        console.log(err);
      }
    },
    async mapOtherColumn() {
      const otherColumnArr = [];
      for (const column of this.otherColumn) {
        let pushed = false;
        for (const detail of this.data.asset_details) {
          if (detail.column_no === column.column_no) {
            otherColumnArr.push(detail);
            pushed = true;
          }
        }
        if (!pushed) {
          otherColumnArr.push({ ...column, value: null });
        }
      }
      this.otherColumnAsset = otherColumnArr;
    },
    OpenScanBarcode() {
      localStorage.setItem(
        "registerItem",
        JSON.stringify({
          assetData: this.data,
          manual_asset: this.manual_asset,
          assetFixedId: this.assetFixedId,
        })
      );
      this.$router.push({
        name: "ScanQrPage",
        params: { page: "registerNow" },
        query: { type: "barcode", qr: this.$route.params.id },
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.data.id_qrcode = this.$route.params.id;
    await this.$store.dispatch("getAsset", this.data.id_qrcode).then((res) => {
      if (res === "have this asset") {
        this.$router.push(`/check/${this.$route.params.id}`);
      }
    });
    this.user = this.$store.getters.getUserDetail;

    // await this.$store.dispatch("firstGetAllAsset", {
    //   company_id: this.id_company, //this.user.id_company
    //   isCountingDate: true,
    // });
    this.loadAssetList();
    await this.loadAssetOptions();
    this.loadOtherColumn();

    if (this.getAssetSelectedRegister) {
      this.setDataFromSearchAsset();
      this.isMap = true;
    }

    if (localStorage.getItem("registerItem")) {
      const savedItem = JSON.parse(localStorage.getItem("registerItem"));
      this.data = savedItem.assetData;
      this.assetFixedId = savedItem.assetFixedId;
      this.manual_asset = savedItem.manual_asset;
      localStorage.removeItem("registerItem");
    }

    if (this.$route.query.barcode) {
      this.manual_asset = true;
      this.data.name = this.$route.query.barcode;
      // const assetResponse = res.data.data;
      // const barcode = this.$route.query.barcode;
      // const assetFindFromList = assetResponse.find(
      //   (asset) => asset.id_fixed_asset === barcode
      // );
      // if (assetFindFromList) {
      //   this.assetFixedId = assetFindFromList.id_asset;
      //   this.onSubmit(this.assetFixedId);
      // } else {
      //   this.manual_asset = true;
      //   this.assetFixedId = this.$route.query.barcode;
      // }
    }

    // this.$store.dispatch("sendAllMap", this.id_company); // must change to id company
    // this.$store.dispatch("getimportRegisAsset", this.id_company); //must change to id company

    // this.sendAllMap(this.id_company).then(() => {
    //   this.getAllMap();
    // });
    this.isLoading = false;
    this.hide();

    this.checkMap();
  },
  created() {
    this.data.id_qrcode = this.$route.params.id;
  },
};
</script>

<style lang="scss" scoped>
.search-select {
  width: 100%;
  ::v-deep .vs__search::placeholder,
  ::v-deep .vs__dropdown-toggle {
    background: #f4f4f4;
    border: none;
    height: 40px;
  }
}

s .sub-text {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  border: 2px solid #eee;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}
.btn-indoor-modal {
  width: 77px;
  text-align: center;
  margin: auto;
}

.indoor-map-modal {
  padding-bottom: 1rem;
}
.modal-content {
  padding: 1rem;
  border-radius: 35px;
  height: auto;

  & label {
    color: #4b4b4b;
    padding: 0.4rem;
  }
}

.indoor-map {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%; // or width instead of max-width
  background: white;
  // position: relative;
  border: 1px solid #d0d0d0 !important;
  border-radius: 11px;
  overflow: hidden;

  /* border-radius: 0.75rem; */

  img {
    height: 200%; /* probably looks neater if auto */
    width: 200%; /* double width image to show only first quarter */
    vertical-align: bottom; /* moves image to true text bottom */

    /* width: auto;
    max-width: 100% !important;
    height: auto; */
    /* object-fit: scale-down; */
  }
}

.indoor-map-none {
  border-radius: 12px;
  border: 1px solid #bcbaba;
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://api.verscan.com/download/no-img.jpg");
  background-size: cover;
  p {
    opacity: 1;
    font-size: 1.25rem;
    box-shadow: 0 0 black;
    background: white;
  }
}
.frame-profile {
  position: absolute;
  // top: -0.2rem;
  top: 173px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  /* text-align: center; */
  margin: auto;
  background: #e0e0e0;
  border-radius: 10px;
  border: 2px solid white;
  z-index: 90;
}
.upload-icon-plus {
  z-index: 90;
  position: absolute;
  bottom: 30%;
  right: 1px;

  img {
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 30%;
    width: 31px;
    height: 31px;
  }
}
.upload-icon-content {
  display: flex;
  font-size: 4rem;
  align-items: center;
}
.banner-profile {
  height: 104px;
  width: 104px;
  border-radius: 10px;
  z-index: 10;
  object-fit: cover;
}
.banner {
  // margin-top: -0.5rem;
  height: 4rem;
  width: 4rem;
  z-index: 10;
}

.register {
  overflow: auto;
  position: relative;
  z-index: 2;
}
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

input,
select,
textarea {
  border-radius: 8px !important;
  background-color: #e0e0e0;
  border: none;
  padding: 0.5rem 1rem;
}

// select {
//   background: white;
// }

// textarea {
//   padding-bottom: 1.5rem;
// }

.btn {
  padding: 0.55rem 1rem 0.55rem 1rem;
}

.btn-asset {
  padding: 0 !important;

  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 12px;
}

.custom-input-file {
  cursor: pointer;
  padding: 0.55rem 1rem 0.55rem 1rem;
}

.header-text {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.card-div-2 {
  margin: auto;
  /* width: 100vw; */
  height: 100vh;
  // background: linear-gradient(90deg, rgb(0, 123, 255), rgb(0, 62, 128));
}

.card-form-login {
  padding-top: 3rem;
}

// @media only screen and (min- {
//   .footer[data-v-40ab164b] {
//     position: relative;
//     width: 100%;
//     height: 180% !important;
//     margin: 0 !important;
//     padding-top: 0;
//     box-shadow: 0px 0px 10px #00000067;
//   }
// }
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media only screen and (min-width: 1025px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 15%;
    margin-right: 15%;
  }
  .footer {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh !important;
    margin: 0 !important;
    padding-top: 0;
    box-shadow: 0px 0px 10px #00000067;
  }
}
@media only screen and (min-width: 2500px) {
  .card-form-login {
    padding-top: 3rem;
    margin-left: 15%;
    margin-right: 15%;
  }
  .footer[data-v-40ab164b] {
    position: relative;
    width: 100%;
    height: 100% !important;
    margin: 0 !important;
    padding-top: 0;
    box-shadow: 0px 0px 10px #00000067;
  }
}
.upper-canvas {
  position: relative;
  width: 100%;
  height: 100%;
}
.canvas-container {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  // user-select: none;
}
.__view {
  position: relative;
}
.upload-map {
  background: white;
}

.sub-menu {
  color: $font-grey;
  button : {
    margin-right: 1rem;
  }
}

.filter {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-radius: 10px;
  // border: 1px solid #007bff;
  background-color: red;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.filter-box {
  height: 45px;
  border: 0px;
  background-color: #e0e0e0;
}

.filter-count {
  border: 2px solid #007afe;
  border-radius: 5px;
}

.filter-count-box {
  color: #007afe;
}

#basic-addon1 {
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}

#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}

.scan-btn {
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #0154ae;
  padding: 0rem 0.75rem 0rem 0.75rem;
  color: #0154ae;
}

/* .date-input-icon {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: #007afe;
} */
</style>
